import React, { FC } from 'react';
import { Button, Dropdown, useDropdownHover } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';
import cn from 'classnames';

import { ShowSurveyEvent } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

import { FooterLink } from '../footer-link';
import { FooterLink as FooterLinkType, FooterSocialLink } from '../../types/footer.interface';
import { MenuItemComponentProps } from '../../types/menu-item.interface';
import { ChangeRegionButton } from '../change-region-button/change-region-button';
import { GEO_CONFIRM_FOOTER, sendAnalyticsEvent } from '../../analytics';
import { useUserRegionContext } from '../../shell';
import { FooterPhone } from '../footer-phone';
import { FEEDBACK_SURVEY_LINK, WORK_TIME } from '../../constants';
import commonStyles from '../common.module.css';
import styles from './top.module.css';
import { useConfigContext } from '../../shell/contexts';

const showFeedbackPopup = () => {
    const showFeedbackEvent: ShowSurveyEvent = {
        type: 'survey:show',
        category: 'simple',
        data: {
            link: FEEDBACK_SURVEY_LINK,
            query: {},
            type: 'ces',
        },
    };
    EventBus?.getInstance()?.publish(showFeedbackEvent);
};

type TopProps = {
    top: FooterLinkType[][];
    menuItemComponent: React.FC<MenuItemComponentProps>;
    logoHref?: string;
    downloadAppQrCode: string;
    socialLinks: FooterSocialLink[];
};

const QRCodeControl: React.FC<{ imageSrc: string }> = ({ imageSrc }) => {
    const { control } = useDropdownHover();

    return (
        <span className={styles.qrImage} ref={control}>
            <img src={imageSrc} alt="QR-код" />
        </span>
    );
};

export const Top: FC<TopProps> = ({ top, menuItemComponent, socialLinks, downloadAppQrCode, logoHref }) => {
    const { userRegionName, showSelectRegionModal } = useUserRegionContext();
    const { cdnUrl, supportPhone, backupSupportPhone } = useConfigContext();

    const handleChangeRegionButtonClick = () => {
        showSelectRegionModal?.();
        sendAnalyticsEvent(GEO_CONFIRM_FOOTER);
    };

    const SocialLink: FC<FooterSocialLink> = ({ name, icon, href }) => (
        <FooterLink
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            analytics={`FooterLink${icon[0].toUpperCase() + icon.substring(1)}`}
            component={menuItemComponent}
            className={styles.socialLink}
        >
            <span className={styles.icon}>
                <Icon name={icon} size="s" />
            </span>{' '}
            <span className={cn(styles.icon, styles.mobile)}>
                <Icon name={icon} size="m" />
            </span>{' '}
            <span className={styles.name}>{name}</span>
        </FooterLink>
    );
    return (
        <div className={styles.top}>
            <div className={cn(commonStyles.row, styles.linksRow)}>
                <div className={cn(commonStyles.col, styles.logoCol)}>
                    <FooterLink to={logoHref} component={menuItemComponent}>
                        <img className={styles.logo} src={`${cdnUrl}/assets/images/m2logo.svg`} alt="" />
                    </FooterLink>
                    <div className={styles.location}>
                        <FooterPhone
                            phone={{ phoneNumber: supportPhone! }}
                            className={cn(styles.phone, styles.supportPhone)}
                        />
                        {!!backupSupportPhone && (
                            <div className={styles.backupSupportPhone}>
                                <FooterPhone phone={{ phoneNumber: backupSupportPhone }} className={styles.phone} />
                            </div>
                        )}
                        <div className={styles.workTime}>{WORK_TIME}</div>
                    </div>
                    {userRegionName && (
                        <div className={styles.changeRegionButton}>
                            <ChangeRegionButton regionName={userRegionName} onClick={handleChangeRegionButtonClick} />
                        </div>
                    )}
                </div>
                {top.map((links, i) => (
                    <ul key={i} className={cn(commonStyles.col, styles.linksCol)}>
                        {links.map((link) => (
                            <li key={link.to || link.href} className={styles.linksColItem}>
                                <FooterLink cdnUrl={cdnUrl} {...link} component={menuItemComponent}>
                                    {link.name}
                                </FooterLink>
                            </li>
                        ))}
                    </ul>
                ))}
                <ul className={cn(commonStyles.col, styles.socialCol)}>
                    {socialLinks.map((link) => (
                        <li key={link.href} className={styles.socialColItem}>
                            <SocialLink {...link} />
                        </li>
                    ))}
                </ul>
            </div>
            <div className={cn(commonStyles.row, styles.contactsRow)}>
                <div className={commonStyles.col}>
                    <Button variant="secondary" size="s" onClick={showFeedbackPopup} fullWidth>
                        Оставить обратную связь
                    </Button>
                </div>

                <div className={cn(commonStyles.col, styles.appsCol)}>
                    <FooterLink
                        className={styles.app}
                        analytics="FooterLinkAppStore"
                        href="https://trk.mail.ru/c/uifgv8?mt_idfa={mt_idfa}&mt_campaign=web_footer"
                        rel="nofollow noreferrer noopener"
                        component={menuItemComponent}
                    >
                        <img src={`${cdnUrl}/assets/file-upload-server/93544ccc5d889fe35ad6f03c753c2055.svg`} alt="" />
                    </FooterLink>
                    {/* <FooterLink
                        className={styles.app}
                        analytics="FooterLinkGooglePlay"
                        href="https://trk.mail.ru/c/r3x845?mt_gaid={mt_gaid}"
                        rel="nofollow noreferrer noopener"
                        component={menuItemComponent}
                    >
                        <img src={`${cdnUrl}/assets/file-upload-server/c1ca83273963eeb674fb92a549d4f9e5.svg`} alt="" />
                    </FooterLink> */}

                    <FooterLink
                        className={styles.app}
                        analytics="FooterLinkRuStore"
                        href="https://trk.mail.ru/c/htywr2?mt_gaid={mt_gaid}&mt_campaign=web_footer"
                        rel="nofollow noreferrer noopener"
                        component={menuItemComponent}
                    >
                        <img
                            src={`${cdnUrl}/assets/file-upload-server/8606c638b00fe58269a5a687ed05d3e2.svg`}
                            alt="RuStore"
                        />
                    </FooterLink>

                    <FooterLink
                        className={styles.app}
                        analytics="FooterLinkHuawei"
                        href="https://trk.mail.ru/c/n9fk639?mt_gaid={mt_gaid}&mt_campaign=web_footer"
                        rel="nofollow noreferrer noopener"
                        component={menuItemComponent}
                    >
                        <img src={`${cdnUrl}/assets/file-upload-server/f24c8e5b58b995f37dc1afcb386456f6.svg`} alt="" />
                    </FooterLink>
                    <Dropdown>
                        <QRCodeControl imageSrc={downloadAppQrCode} />
                        <Dropdown.Content>
                            <div className={styles.qrDesc}>
                                <img src={downloadAppQrCode} alt="QR-код" className={styles.qrDescImage} />
                                <span className={styles.qrDescText}>
                                    Наведите камеру телефона на код, чтобы установить приложение
                                </span>
                            </div>
                        </Dropdown.Content>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};
