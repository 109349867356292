import React, { useCallback, useEffect, useState } from 'react';

import { UpdatesRefreshEvent, UpdatesRefreshEventType, UpdatesRefreshPayload } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';
import { UpdatesData, UpdatingServices } from '@vtblife/layout-config/types';

import { reportErrorToSentry } from '../utils';
import { updatesApiService } from '../../services/updates-api';
import { useAuthorizeContext } from '../contexts';

const eventBus = EventBus.getInstance();

export const useUpdates = () => {
    const { user, isAuthorized } = useAuthorizeContext();
    const [servicesUpdates, setServicesUpdates] = useState<UpdatesData>({});
    const bcRef = React.useRef(typeof BroadcastChannel === 'undefined' ? undefined : new BroadcastChannel('updates'));
    const refresh = useCallback((services: UpdatingServices[]) => {
        const event: UpdatesRefreshEvent = {
            type: 'updates:refresh',
            category: 'simple',
            data: {
                services,
            },
        };
        eventBus.publish(event);
    }, []);

    useEffect(() => {
        if (!isAuthorized) return;

        let abortController: AbortController | undefined;
        const broadcastChannel = bcRef.current;

        const refreshData = async (services?: UpdatingServices[]) => {
            abortController?.abort();
            abortController = typeof AbortController !== 'undefined' ? new AbortController() : undefined;

            try {
                const { data } = await updatesApiService.getUpdates(services);
                setServicesUpdates((servicesUpdates) => ({ ...servicesUpdates, ...data }));
            } catch (error) {
                reportErrorToSentry({ error });
            }
        };

        refreshData();

        const handleBroadcastChannelMessage = (event: MessageEvent<{ services: UpdatingServices[] }>) => {
            refreshData(event.data.services);
        };

        const sub = eventBus.subscribe<UpdatesRefreshEventType, UpdatesRefreshPayload>(
            'updates:refresh',
            ({ data }) => {
                if (!isAuthorized) return;
                const { services } = data;
                refreshData(services as UpdatingServices[]);
                broadcastChannel?.postMessage({ services });
            },
        );

        broadcastChannel?.addEventListener('message', handleBroadcastChannelMessage);

        // Fallback, если BroadcastChannel не поддерживается на старых браузерах
        const handle = broadcastChannel ? undefined : setTimeout(() => refreshData(), 20 * 1000);
        return () => {
            sub.unsubscribe();
            handle && clearTimeout(handle);
            abortController?.abort();
            broadcastChannel?.removeEventListener('message', handleBroadcastChannelMessage);
        };
    }, [isAuthorized, user, bcRef]);

    return { servicesUpdates, refresh };
};
