import React, { useContext } from 'react';

import { IconLink } from '../icon-link/icon-link';
import { getPersonalButtonUrl } from '../utils';
import M2HeartCircleIcon from '../images/m2-heart-circle.component.svg';
import M2BookmarkCircleIcon from '../images/m2-bookmark-circle.component.svg';
import { HeaderContextValue } from '../header-context';
import { FreshDot } from '../../fresh-dot/fresh-dot';
import { useServiceUpdatesContext } from '../../shell/contexts';

export const PersonalButtons = () => {
    const { roleType, personalButtons } = useContext(HeaderContextValue);
    const freshCount = useServiceUpdatesContext('saved-search');

    return (
        <>
            <IconLink
                url={getPersonalButtonUrl(roleType, personalButtons?.favorites)}
                dataTest="favorites"
                title="Избранное"
                iconComponent={<M2HeartCircleIcon />}
            />
            <IconLink
                url={getPersonalButtonUrl(roleType, personalButtons?.savedSearch)}
                title="Подписки"
                iconComponent={
                    <FreshDot isCircleVisible={Boolean(freshCount)} variant="icon-link">
                        <M2BookmarkCircleIcon />
                    </FreshDot>
                }
            />
        </>
    );
};
