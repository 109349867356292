import { useEffect, useState } from 'react';

import { RoleType } from '@vtblife/event-bus-events';
import {
    getVerificationStatus,
    V1VerificationStatus,
} from '@vtblife/client-verification-api/axios-gen/m2pro_verification_service';

import { reportErrorToSentry, useAuthorizeContext } from '../shell';

export const useDisplayVerificationStatus = () => {
    const { user } = useAuthorizeContext();
    const [verificationStatus, setVerificationStatus] = useState<V1VerificationStatus | undefined>();

    const { currentRoleType, companyId, permissions, userId } = user || {};
    const isVerificationAvailable =
        permissions?.includes('/client-verification/m2pro/verification:read') ||
        currentRoleType == RoleType.Professional;

    const isAlreadyPassedVerification = verificationStatus && ['STATUS_APPROVED'].includes(verificationStatus);
    const isClientVerificationAvailableToSend =
        isVerificationAvailable &&
        verificationStatus &&
        ['STATUS_UNSPECIFIED', 'STATUS_DRAFT', 'STATUS_ADDITIONAL_DATA_REQUIRED'].includes(verificationStatus);

    useEffect(() => {
        const checkVerificationStatus = async () => {
            if (isVerificationAvailable) {
                try {
                    const params = companyId ? { companyId } : { userId };
                    const { status } = await getVerificationStatus({ params });
                    setVerificationStatus(status);
                } catch (error) {
                    reportErrorToSentry({ error });
                }
            }
        };
        checkVerificationStatus();
    }, [isVerificationAvailable, companyId, userId]);

    return { isVerificationAvailable, isAlreadyPassedVerification, isClientVerificationAvailableToSend };
};
