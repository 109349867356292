import * as Sentry from '@sentry/browser';
import { NotificationEvent } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

const fallbackCopyTextToClipboard = (content: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = content;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    const isSuccessful = document.execCommand('copy');
    document.body.removeChild(textArea);
    if (!isSuccessful) {
        throw new Error('Copying has failed in fallback');
    }
};

export const copyToClipboard = async (content: string, onSuccess?: VoidFunction) => {
    try {
        if (navigator?.clipboard) {
            await navigator.clipboard.writeText(content);
        } else {
            fallbackCopyTextToClipboard(content);
        }
        const notification: NotificationEvent = {
            type: 'app:notification',
            data: {
                type: 'info',
                message: 'Информация об ошибке скопирована в буфер обмена',
            },
            category: 'simple',
        };
        EventBus.getInstance().publish(notification);
        onSuccess?.();
    } catch (error) {
        Sentry.captureMessage('Copying to clipboard has failed', {
            level: 'warning',
            extra: { error, contentToCopy: content },
        });
    }
};
