import { matchPath } from 'react-router';

import { Route } from '../../../../typings/configs';

export const isAuthorizedPath = (testPath: string, bundleTree: Route[]) => {
    const route = bundleTree.find(({ path, exact }) => Boolean(matchPath(testPath, { path, exact })));
    return (
        route?.availableForNotAuthorized === false || route?.requiredPermissions?.length || route?.companyIds?.length
    );
};
