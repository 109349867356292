import { HeaderBaseItem, HeaderTab, HeaderTabItem } from '@vtblife/layout-config/types';
import { RoleType } from '@vtblife/event-bus-events';

import { TabsIds } from './constants';
import { HeaderPersonalButton } from '../types/header.interface';

const CLASSIFIED_LINKS = ['/nedvizhimost/kupit-kvartiru/', '/nedvizhimost/snyat-kvartiru/', '/novostroyki/'];

// Так как ссылки на классфаийд в конфиге хедера захардкожены через /moskva/{CLASSIFIED_LINKS},
// но pathname может быть /sankt-peterburg/{CLASSIFIED_LINKS},
// то не обходимо делать проверку на наличие одной из захардкоженной подстроки CLASSIFIED_LINKS,
// чтобы корректно подсветить пункт "Поиск" в хедере
const compareClassifiedPathname = (pathname?: string, href?: string) => {
    const classifiedLink = CLASSIFIED_LINKS.find((link) => href?.includes(link));
    return classifiedLink && pathname?.includes(classifiedLink);
};

const comparePathProps = (pathname: string, props: { path: string; exact?: boolean }): boolean => {
    if (props.exact) {
        return pathname === props.path;
    }
    return props.path !== '/' && pathname?.startsWith(props.path);
};

// Так как произошел переезд на региональные ссылки,
// то в url и path могут быть абсолютными и простой матч по ним с pathname не сработает
const extractItemPathname = (item?: HeaderBaseItem) => {
    const itemHref = item?.url || item?.path;
    return itemHref?.startsWith('http') ? new URL(itemHref).pathname : itemHref;
};

export const comparePathname = (pathname?: string, item?: HeaderBaseItem) => {
    if (!pathname) return false;

    if (item?.activeRoute) {
        return new RegExp(item.activeRoute).test(pathname);
    }

    const itemPathname = extractItemPathname(item);

    if (!itemPathname) {
        return false;
    }

    return (
        compareClassifiedPathname(pathname, itemPathname) ||
        comparePathProps(pathname, { path: itemPathname, exact: item?.exact })
    );
};

export const comparePathnameWithTab = (pathname?: string, item?: HeaderTab | HeaderTabItem): any => {
    return (
        comparePathname(pathname, item) || item?.dropdownMenu?.some((item) => comparePathnameWithTab(pathname, item))
    );
};

export const getActiveTabId = (tabs?: HeaderTab[], pathname?: string) => {
    if (!tabs) {
        return;
    }

    let activeTabId: string = TabsIds.DEFAULT;

    for (const tab of tabs) {
        if (comparePathnameWithTab(pathname, tab)) {
            activeTabId = tab.id;
            break;
        }
    }

    return activeTabId;
};

export const shouldShowPersonalButtons = ({
    currentRoleType,
    pathname,
    items,
}: {
    currentRoleType?: string;
    pathname?: string;
    items?: HeaderTab[];
}): boolean => {
    const activeTabId = getActiveTabId(items, pathname);
    const rolesWithPersonalButtonsSupport = [
        RoleType.Client,
        RoleType.Employee,
        RoleType.Realtor,
        RoleType.Professional,
    ];

    if (
        activeTabId === TabsIds.DEVELOPERS ||
        (currentRoleType && !rolesWithPersonalButtonsSupport.includes(currentRoleType as RoleType))
    ) {
        return false;
    }

    return true;
};

export const getPersonalButtonUrl = (roleType?: string, urls?: HeaderPersonalButton) => {
    if (!urls) return;
    if (!urls.professionalUrl) return urls.url;
    return roleType === 'PROFESSIONAL' || roleType === 'REALTOR' ? urls.professionalUrl : urls.url;
};

export const getOfferPlacementButtonUrl = (isAuthorized?: boolean, urls?: HeaderPersonalButton) => {
    if (!urls) return;
    if (!urls.authorizedUrl) return urls.url;
    return isAuthorized ? urls.authorizedUrl : urls.url;
};
