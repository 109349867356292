import axios from '@vtblife/axios';
import Sentry from '@sentry/browser';
export const checkCaptchaResourcesAvailable = async () => {
    try {
        const response = await axios.get('https://smartcaptcha.yandexcloud.net/captcha.js?withoutProxy=true', {
            timeout: 3000,
        });
        if ((response.headers['Content-Type'] || '').includes('html')) {
            return Sentry.setTag('load-captcha-error', true);
        }
        return Sentry.setTag('load-captcha-error', false);
    } catch (error) {
        Sentry.setTag('load-captcha-error', true);
    }
};
