import React, { useContext } from 'react';
import { Sluggable } from '@m2-gatsby/data-input-pipeline/src/types';

export interface SupportSegmentContext {
    activeSupportSegment?: {
        slug?: string;
        title?: string;
    };
    setActiveSupportSegment?: (activeSupportSegment: Sluggable) => void;
}

export interface SupportDataContext {
    supportSegment: SupportSegmentContext;
}

export const SupportDataContextValue = React.createContext<SupportDataContext>({
    supportSegment: {},
});

export const useSupportSegmentContext = () => useContext(SupportDataContextValue).supportSegment;
