import axios, { AxiosInstance, AxiosResponse, CancelToken } from '@vtblife/axios';

export type GeoObject = {
    displayName: string;
};

export type GeoSuggestion = {
    label: string;
    value: number;
    narrowRegion?: {
        id: number;
        name: number;
    };
};

export type GeoSuggestions = GeoSuggestion[];

class GeoApiService {
    transport: AxiosInstance;

    constructor(name = 'root') {
        this.transport = axios.create({
            baseURL: `/api/${name}-bff/`,
        });
    }

    async getGeoSuggest(text: string, cancelToken?: CancelToken): Promise<AxiosResponse<GeoSuggestions>> {
        return this.transport.get('/api/get-geo-suggest', { params: { text }, cancelToken });
    }

    // TODO: DEPRECATED
    async getGeoObjectById(regionId: number): Promise<AxiosResponse<GeoObject | null>> {
        return this.transport.get('/api/get-geo-object-by-id', { params: { regionId } });
    }

    async getGeoObjectByIdV2(regionId: number): Promise<AxiosResponse<GeoObject | null>> {
        return this.transport.get('/api/get-geo-object-by-id-v2', { params: { regionId } });
    }

    async detectRegion(): Promise<AxiosResponse<GeoObject>> {
        return this.transport.get('/api/detect-region');
    }
}

export const geoApiService = new GeoApiService();
