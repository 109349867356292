import { isMobileWide } from '../helpers';

let windowOffset = 0;

export const preventBodyScrollOverlayOpened = (isOpened: boolean) => {
    if (!isMobileWide()) return;

    if (isOpened) {
        windowOffset = window?.scrollY;
        document.body.classList.add('headerMenuOpened');
        document.body.setAttribute('style', `top: ${-windowOffset}px;`);
    } else {
        document.body.classList.remove('headerMenuOpened');
        document.body.removeAttribute('style');
        window.scrollTo(0, windowOffset);
        windowOffset = 0;
    }
};
