import { EventBus } from '@vtblife/event-bus';
import {
    InitUsedeskEventType,
    InitUsedeskPayload,
    HideUsedeskEventType,
    HideUsedeskPayload,
    ShowUsedeskEventType,
    ShowUsedeskPayload,
    Subscription,
} from '@vtblife/event-bus-events';

import { RootStore } from './root';

type Mode = 'show' | 'hide';

export class UsedeskStore {
    private eventBus = EventBus.getInstance();
    rootStore: RootStore;
    private isInit = false;
    private mode: Mode | null = null;
    private subscriptions: Subscription[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.subscribeUsedeskToggle();
    }

    cleanUp() {
        this.subscriptions.forEach((_) => _.unsubscribe());
    }

    private subscribeUsedeskToggle(): void {
        this.subscriptions.push(
            this.eventBus.subscribe<InitUsedeskEventType, InitUsedeskPayload>('usedesk:init', () => {
                this.isInit = true;

                if (this.mode) {
                    this.tryToToggleUsedesk(this.mode);
                }
            }),
            this.eventBus.subscribe<ShowUsedeskEventType, ShowUsedeskPayload>('usedesk:show', () => {
                this.tryToToggleUsedesk('show');
            }),
            this.eventBus.subscribe<HideUsedeskEventType, HideUsedeskPayload>('usedesk:hide', () => {
                this.tryToToggleUsedesk('hide');
            }),
        );
    }

    private tryToToggleUsedesk(mode: Mode) {
        if (this.isInit) {
            window.usedeskMessenger?.toggle(mode === 'show');
        } else {
            this.mode = mode;
        }
    }
}
