// auto generated in scripts/sync-initial-stats.ts, don't change manually

export { initialState } from './initial-state/initial-state';

import { sanktPeterburgConfig } from './initial-state/sankt-peterburg-config';

import { ekbConfig } from './initial-state/ekb-config';

import { nnConfig } from './initial-state/nn-config';

import { novosibirskConfig } from './initial-state/novosibirsk-config';

import { kazanConfig } from './initial-state/kazan-config';

export const regionsConfigs = {
    spb: sanktPeterburgConfig,
    ekb: ekbConfig,
    'nizhniy-novgorod': nnConfig,
    novosibirsk: novosibirskConfig,
    kazan: kazanConfig,
};
