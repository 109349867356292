import React, { useState } from 'react';
import { Modal, Button } from '@vtblife/uikit';
import cn from 'classnames';
import { ModalProps } from '@vtblife/uikit/dist/components/modal/types';

import styles from './change-region-modal.module.css';
import { GeoSuggestion } from '../../../services/geo-api';
import { RegionSuggest } from './region-suggest/region-suggest';

interface ChangeRegionModalProps extends Omit<ModalProps, 'children'> {
    onRegionSelect: (region: GeoSuggestion) => void;
    selectedRegionId?: number;
}

const DEFAULT_REGIONS = [
    {
        id: 3,
        displayName: 'Москва',
    },
    {
        id: 444,
        displayName: 'Санкт-Петербург',
    },
    {
        id: 108,
        displayName: 'Московская область',
    },
    {
        id: 886,
        displayName: 'Ленинградская область',
    },
];

const LEFT_COL = DEFAULT_REGIONS.slice(0, 2);
const RIGHT_COL = DEFAULT_REGIONS.slice(2);

interface DefaultRegionProps {
    region: {
        id: number;
        displayName: string;
    };
    selectedRegionId?: number;
    onClick: (suggestion: GeoSuggestion) => void;
}

const DefaultRegionItem = ({ region: { id, displayName }, selectedRegionId, onClick }: DefaultRegionProps) => {
    const handleClick = () => {
        onClick({
            label: displayName,
            value: id,
        });
    };

    return selectedRegionId === id ? (
        <span className={cn(styles.defaultRegionItemButton, styles.defaultRegionItemButtonMain)}>{displayName}</span>
    ) : (
        <button className={styles.defaultRegionItemButton} onClick={handleClick}>
            {displayName}
        </button>
    );
};

export const ChangeRegionModal: React.FC<ChangeRegionModalProps> = (props) => {
    const [value, setValue] = useState<GeoSuggestion>();
    const handleSuggestChange = (suggestion?: GeoSuggestion) => {
        setValue(suggestion);
    };

    const handleButtonClick = () => {
        value && props.onRegionSelect(value);
    };

    const handleDefaultRegionItemClick = (suggestion: GeoSuggestion) => {
        props.onRegionSelect(suggestion);
    };

    return (
        <Modal {...props} size="m" dataTest="change-region-modal">
            <div className={styles.header}>
                <Modal.Header>Выберите регион</Modal.Header>
            </div>
            <div className={styles.title}>
                <Modal.Title>Выберите регион</Modal.Title>
            </div>
            <div className={styles.form}>
                <span className={styles.formInput}>
                    <RegionSuggest value={value} onChange={handleSuggestChange} />
                </span>
                <span className={styles.formButton}>
                    <Button size="l" fullWidth={true} onClick={handleButtonClick} dataTest="change-region-modal-select">
                        Выбрать
                    </Button>
                </span>
            </div>
            <div className={styles.defaultRegions}>
                <div className={styles.defaultRegionsCol}>
                    {LEFT_COL.map((region) => (
                        <div className={styles.defaultRegionItem} key={region.id}>
                            <DefaultRegionItem
                                region={region}
                                onClick={handleDefaultRegionItemClick}
                                selectedRegionId={props.selectedRegionId}
                            />
                        </div>
                    ))}
                </div>
                <div className={styles.defaultRegionsCol}>
                    {RIGHT_COL.map((region) => (
                        <div className={styles.defaultRegionItem} key={region.id}>
                            <DefaultRegionItem
                                region={region}
                                onClick={handleDefaultRegionItemClick}
                                selectedRegionId={props.selectedRegionId}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};
