import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import type { AppMenuSidebarEventData, AppMenuSidebarToggleEventType } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

import { TABLET_QUERY } from '../../helpers/is-mobile';

const eventBus = EventBus.getInstance();

export const useSidebarToggle = () => {
    const [tabletOpen, setTabletOpen] = useState(false);
    const [desktopOpen, setDesktopOpen] = useState(true);
    const isTablet = useMedia(TABLET_QUERY);

    const open = isTablet ? tabletOpen : desktopOpen;
    const setOpen = isTablet ? setTabletOpen : setDesktopOpen;

    useEffect(() => {
        const subscription = eventBus.subscribe<AppMenuSidebarToggleEventType, AppMenuSidebarEventData>(
            'app:sidebar:toggle',
            (event) => setOpen(event.data.isOpen),
        );

        return () => {
            subscription.unsubscribe();
        };
    }, [setOpen]);

    const handleClickOutside = () => {
        if (tabletOpen) {
            setTabletOpen(false);
        }
    };

    return { open, setOpen, handleClickOutside };
};
