import cn from 'classnames';
import React from 'react';

import styles from './fresh-dot.module.css';

export const FreshDot: React.FC<{
    isCircleVisible?: boolean;
    isSidebarClosed?: boolean;
    variant?: 'menu' | 'icon-link';
}> = ({ isSidebarClosed, isCircleVisible, variant, children }) => {
    if (!isCircleVisible) {
        return <>{children}</>;
    }

    return (
        <div className={cn(styles.container, variant === 'icon-link' ? styles['icon-link-container'] : undefined)}>
            {children}
            <span className={cn(styles.circle, variant && styles[variant], isSidebarClosed && styles.closed)}></span>
        </div>
    );
};
