import React from 'react';
import cn from 'classnames';

import styles from './default.module.css';
import { Props as LayoutProps } from '../layout';

export const DefaultLayout: React.FC<LayoutProps> = (props) => {
    const { header, footer, sidebar, isAnonymous, className, children } = props;
    const isPersonalArea = Boolean(sidebar);
    const contentFullHeight = !footer; //contentWrapper растянется на всю высоту. Как на странице /login

    if (isAnonymous && isPersonalArea) {
        return (
            <div className={cn(className, styles.anonymousArea)}>
                {header}
                <main className={cn(styles.main)}>{children}</main>
            </div>
        );
    }
    if (isPersonalArea) {
        return (
            <div className={cn(className, styles.personalArea)}>
                {sidebar}
                <main className={cn(styles.main)}>{children}</main>
            </div>
        );
    }
    return (
        <div className={cn(className, styles.publicArea, { [styles.contentFullHeight]: contentFullHeight })}>
            {header}
            <div className={styles.contentWrapper}>
                <main className={styles.main}>{children}</main>
            </div>
            {footer}
        </div>
    );
};
