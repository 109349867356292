import { ToastOptions, ToastContainerProps } from 'react-toastify';

import { SlideWithFade } from './components/animations/animations';

export const TOAST_CONTAINER_CONFIG: ToastContainerProps = {
    limit: 4,
    newestOnTop: false,
    transition: SlideWithFade,
    draggable: false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
};

export const TOAST_CONFIG: ToastOptions = {
    autoClose: 4000,
};

/** Props, проброшенные из react-toastify */
export interface ToastifyPassedProps {
    /** Закрывает popup */
    closeToast?: () => void;
}
