import React, { useCallback, useState } from 'react';
import Icon from '@vtblife/uikit-icons';
import cn from 'classnames';

import styles from './chat-stub.module.css';
import { useConfigContext } from '../../contexts';
import { clearPhone } from '../../../helpers';

export const ChatStub = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openPopup = useCallback(() => setIsOpen(true), []);
    const closePopup = useCallback(() => setIsOpen(false), []);
    const { supportPhone = '' } = useConfigContext();

    return (
        <>
            <div className="chatWidgetButton" onClick={openPopup} data-gtm="bank-chat-stub">
                <Icon name="question" />
            </div>
            {isOpen && (
                <div className={styles.popup}>
                    <div onClick={closePopup} className={cn(styles.button, styles.closeButton)}>
                        <Icon name="times-circle" size="s" />
                    </div>
                    <p className={styles.text}>
                        Если у&nbsp;вас возникли <br />
                        вопросы, свяжитесь с&nbsp;нами:
                    </p>
                    <ul className={styles.contacts}>
                        <li>
                            <a
                                className={styles.contactsButton}
                                href="//wa.me/79917773233"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <span className={styles.icon}>
                                    <Icon name="whatsapp" />
                                </span>
                                79917773233
                            </a>
                        </li>
                        <li>
                            <a
                                className={styles.contactsButton}
                                href="//telegram.me/M2_b2b_bot"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <span className={styles.icon}>
                                    <Icon name="telegram" />
                                </span>
                                @M2_b2b_bot
                            </a>
                        </li>
                        <li>
                            <a className={styles.contactsButton} href="mailto:vtb@m2.ru">
                                <span className={styles.icon}>
                                    <Icon name="envelope" />
                                </span>
                                vtb@m2.ru
                            </a>
                        </li>
                        <li>
                            <a className={styles.contactsButton} href={`tel:${clearPhone(supportPhone)}`}>
                                <span className={styles.icon}>
                                    <Icon name="phone" />
                                </span>
                                {supportPhone}
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};
