import React from 'react';

import styles from './svg-icon.module.css';

interface SvgIconProps {
    iconComponent: React.ReactNode;
    color?: string;
    size?: Size;
}

const sizes: Record<Size, number> = {
    m: 24,
};

type Size = 'm';

export const SvgIcon: React.FC<SvgIconProps> = ({ iconComponent, size: externalSize = 'm', color }) => {
    const size: number = sizes[externalSize];
    return (
        <i
            className={styles.headerSvgIconContainer}
            style={{ fontSize: `${size}px`, lineHeight: `${size}px`, ...(color && { color }) }}
        >
            {iconComponent}
        </i>
    );
};
