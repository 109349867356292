import React from 'react';
import { NotificationPayload, NotificationContentOptions } from '@vtblife/event-bus-events';

import { ComprehensiveErrorData, getTrivialErrorTypesMessage } from './error-details-extractors';
import { DetailedErrorContent } from '../components/notification-toaster/components/detailed-error-content/detailed-error-content';
import { ReportErrorControl } from '../components/notification-toaster/components/detailed-error-content/report-error-control';
import { MESSAGES } from '../../constants';

export const composeNotification = (comprehensiveErrorData?: ComprehensiveErrorData, sentryEventId?: string) => {
    const notification: NotificationPayload = {
        type: 'error',
        message: getTrivialErrorTypesMessage(comprehensiveErrorData?.data?.parsedType),
        options: { autoClose: false },
    };
    if (comprehensiveErrorData) {
        const { data = {}, ui = {} } = comprehensiveErrorData;
        notification.message = <DetailedErrorContent {...comprehensiveErrorData} sentryEventId={sentryEventId} />;
        const { httpStatus } = data;
        const { title, footerFn } = ui;
        const options: NotificationContentOptions = {
            title: `${httpStatus ? httpStatus + ': ' : ''}${title || MESSAGES.unknownError}`,
            footerFn:
                footerFn ||
                (({ closePopup }) => (
                    <ReportErrorControl
                        {...comprehensiveErrorData}
                        sentryEventId={sentryEventId}
                        onClose={closePopup}
                    />
                )),
        };
        notification.options = { ...notification.options, ...options };
    }
    return notification;
};
