import { AuthInfo, User } from '@vtblife/event-bus-events';
import { getPrincipalDetails, V1PrincipalDetails } from '@vtblife/authz-api/axios-gen/principal_service';
import { reportErrorToSentry } from '@vtblife/layout-components';
import stringify from 'json-stringify-safe';
import { EMPTY_USER_INFO } from '@vtblife/layout-config';

class AuthzApiService {
    getAuthInfo = async (): Promise<AuthInfo> => {
        try {
            const response = await getPrincipalDetails({});
            const principal: (V1PrincipalDetails & { permissions?: Array<string> }) | undefined = response.principal;
            if (!principal) {
                reportErrorToSentry({
                    error: `Unexpected auth info response: ${stringify(response)}.`,
                    additionalData: { response },
                });
                return { user: EMPTY_USER_INFO };
            }
            const permissions = principal.actions || [];
            delete principal.actions;

            return {
                user: {
                    ...principal,
                    roles: principal.roles || [],
                    permissions,
                } as User,
            };
        } catch (error) {
            if (error?.response?.status === 401) {
                return { user: EMPTY_USER_INFO };
            } else {
                throw error;
            }
        }
    };
}

export const authzApiService = new AuthzApiService();
