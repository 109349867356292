import React, { useCallback } from 'react';
import { Button } from '@vtblife/uikit';

import { cookieApiService } from '../../../services/cookie-api';
import { reportErrorToSentry } from '../../utils';

export const CookieConsent = () => {
    const acceptCookies = useCallback(() => {
        cookieApiService
            .setCookieConsent()
            .catch((error) => {
                reportErrorToSentry({
                    error,
                    extra: { when: 'Failed to set cookie consent' },
                    level: 'warning',
                });
            })
            .finally(() => {
                window.__hideCookiesModal();
            });
    }, []);

    return (
        // never change this className, see __hideCookiesModal logic
        <div className="cookie-consent-wrapper">
            <div className="cookie-consent-content">
                <span className="cookie-consent-text">
                    &laquo;Метр квадратный&raquo; использует cookie. &nbsp;
                    <a
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                        href="https://cdn.m2.ru/static-documents/cookie-policy.pdf"
                        className="cookie-consent-link"
                        rel="noreferrer"
                    >
                        Узнать подробнее
                    </a>
                    .
                </span>
            </div>
            <Button variant="primary-alt-bg" dataTest="accept-cookie-button" fullWidth onClick={acceptCookies} size="s">
                Принять
            </Button>
        </div>
    );
};
