import { action, computed, observable } from 'mobx';
import { HeaderConfig } from 'typings/configs';

import { RootStore } from './root';

export class HeaderStore {
    rootStore: RootStore;

    @observable header: HeaderConfig;

    constructor(rootStore: RootStore, initialState?: InitialState['headerStore']) {
        this.rootStore = rootStore;
        if (initialState) {
            this.setInitialState(initialState);
        }
    }

    setInitialState(initialState: InitialState['headerStore']) {
        Object.assign(this, initialState);
    }

    @computed get isInitialized() {
        return Boolean(this.header);
    }

    @action
    setHeader(header: HeaderConfig) {
        this.header = header;
    }
}
