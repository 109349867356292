import axios, { AxiosInstance, AxiosResponse } from '@vtblife/axios';
import { UpdatesData } from '@vtblife/layout-config/types';

class UpdatesApiService {
    transport: AxiosInstance;

    constructor(name = 'root') {
        this.transport = axios.create({
            baseURL: `/api/${name}-bff/`,
            retriesConfig: {
                retries: 0,
            },
        });
    }

    async getUpdates(services?: string[]): Promise<AxiosResponse<UpdatesData>> {
        return this.transport.get('/api/get-updates', { params: { services } });
    }
}

export const updatesApiService = new UpdatesApiService();
