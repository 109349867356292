import { configureUnhandledErrorsNotification } from '@vtblife/layout-components/shell/utils';

import { configureSentry } from '../services/sentry';
import { configureBusEventsNotification } from '../services/notification-event-handler';
import { RootClientData } from '../services/notification-event-handler/utils';
import { configureBrowserStorage } from '../services/storage';

export function bootstrapBrowserEnvironment(
    environment: string | undefined,
    extra?: Record<string, any>,
    displayToasterErrorNotification?: boolean,
) {
    const rootClientData = new RootClientData(extra?.userStore?.deviceIp);

    configureBrowserStorage();
    configureSentry(environment, extra);
    configureUnhandledErrorsNotification(rootClientData, extra, displayToasterErrorNotification);
    configureBusEventsNotification(rootClientData);
}
