import React, { FC, useContext } from 'react';
import Icon from '@vtblife/uikit-icons';
import cn from 'classnames';

import styles from './change-region-button.module.css';
import { HeaderContextValue } from '../header-context';

interface ChangeRegionButtonProps {
    regionName?: string;
    onClick: () => void;
}

export const ChangeRegionButton: FC<ChangeRegionButtonProps> = ({ onClick, regionName }) => {
    const { isTransparent } = useContext(HeaderContextValue);
    return (
        <>
            <button className={styles.changeRegionButton} onClick={onClick} data-test="detect-region-btn">
                <span
                    className={cn(styles.changeRegionButtonIcon, {
                        [styles.changeRegionButtonIconTransparent]: isTransparent,
                    })}
                >
                    <Icon name="placemark" size="s" />
                </span>
                <span
                    className={cn(styles.changeRegionButtonText, {
                        [styles.changeRegionButtonTextTransparent]: isTransparent,
                    })}
                >
                    {regionName}
                </span>
            </button>
            <button className={styles.changeRegionButtonMobile} onClick={onClick} data-test="detect-region-btn-mobile">
                <span className={styles.changeRegionButtonMobileText}>{regionName}</span>
                <span className={styles.changeRegionButtonMobileControl}>Изменить</span>
            </button>
        </>
    );
};
