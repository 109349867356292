import React from 'react';
import cn from 'classnames';
import { NotificationContentOptions } from '@vtblife/event-bus-events';

import { ToastifyPassedProps } from '../../toastify-config';
import styles from './toaster-content.module.css';

export const ToasterContent: React.FC<NotificationContentOptions & ToastifyPassedProps> = (props) => {
    const { title, children, footerFn, closeToast } = props;

    return (
        <div className={cn(styles.container, title && styles.withTitle)}>
            {title && <p className={styles.header}>{title}</p>}
            <div className={styles.message}>{children}</div>
            {footerFn && <div className={styles.footer}>{footerFn({ closePopup: closeToast })}</div>}
        </div>
    );
};
