export const isMobile = () => {
    return window.matchMedia('(max-width: 768px)').matches;
};
export const MOBILE_WIDE_QUERY = '(max-width: 959px)';
export const isMobileWide = () => {
    return window.matchMedia(MOBILE_WIDE_QUERY).matches;
};

export const isScreenBreakpointM = () => {
    return window.matchMedia('(max-width: 1200px)').matches;
};

export const TABLET_QUERY = '(max-width: 1024px)';
export const isScreenBreakpointSSidebar = () => {
    return window.matchMedia(TABLET_QUERY).matches;
};

export const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator?.maxTouchPoints > 0;
};
