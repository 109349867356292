import React, { useCallback, useEffect, useState } from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { EventBus } from '@vtblife/event-bus';
import {
    CaptchaChallengeHiddenEvent,
    CaptchaChallengePassedEvent,
    CaptchaChallengeVisibleEvent,
    ShowCaptchaEventType,
    ShowCaptchaPayload,
    CaptchaExecuteEventType,
    CaptchaExecuteEventPayload,
} from '@vtblife/event-bus-events';
import Cookies from 'js-cookie';

import { useConfigContext } from '../shell/contexts';
import { reportErrorToSentry } from '../shell';
import { isAutotestUserAgent } from '../utils';
import { TRUSTED_HOSTS } from '../constants';
import { executeWithCaptcha } from './execute-with-captcha';

export const Captcha: React.FC = () => {
    const { yandexCaptcha, features = [] } = useConfigContext();
    const [visible, setVisible] = useState(false);
    const [hideShield, setHideShield] = useState(true);
    const [testMode, setTestMode] = useState(false);
    const [captchaEnabled, setCaptchaEnabled] = useState(false);

    useEffect(() => {
        // для случаев, когда проверку капчи нужно вырубить разом (например, не оплатили услугу и ее отрубили или сбой в яндексе)
        const isCaptchaEnabled = features.some((item) => item.name === 'yandex-captcha');
        const isAutotest = isAutotestUserAgent(navigator.userAgent);

        const captchaEnabled = !TRUSTED_HOSTS.includes(document.location.hostname) && isCaptchaEnabled && !isAutotest;
        setCaptchaEnabled(captchaEnabled);
    }, [features]);

    const handleChallengeHidden = useCallback(() => {
        setVisible(false);
        setHideShield(true);

        const event: CaptchaChallengeHiddenEvent = {
            type: 'captcha:challenge-hidden',
            category: 'simple',
            data: {},
        };
        EventBus.getInstance().publish(event);
    }, []);

    const handleChallengeVisible = useCallback(() => {
        const event: CaptchaChallengeVisibleEvent = {
            type: 'captcha:challenge-visible',
            category: 'simple',
            data: {},
        };
        EventBus.getInstance().publish(event);
    }, []);

    const handleCaptchaChallengePassed = useCallback((token: string) => {
        setVisible(false);
        setHideShield(true);
        const event: CaptchaChallengePassedEvent = {
            type: 'captcha:challenge-passed',
            category: 'simple',
            data: {
                token,
            },
        };
        EventBus.getInstance().publish(event);
    }, []);

    useEffect(() => {
        const eventBus = (window.eventBus = EventBus.getInstance());
        const subscriptions = [
            eventBus.subscribe<ShowCaptchaEventType, ShowCaptchaPayload>('captcha:show', ({ data }) => {
                const trustTokenCookieName = 'trust_token';
                const isTrustedUser = data?.useTrustToken && Boolean(Cookies.get(trustTokenCookieName));
                if (!captchaEnabled || isTrustedUser) {
                    handleCaptchaChallengePassed('');
                    return;
                }
                setTestMode(Boolean(data.test));
                setHideShield(false);
                setVisible(true);
            }),
            eventBus.subscribe<CaptchaExecuteEventType, CaptchaExecuteEventPayload<any>>(
                'captcha:execute',
                ({ data }) => {
                    executeWithCaptcha({ payload: data });
                },
            ),
        ];

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [captchaEnabled, handleCaptchaChallengePassed]);

    if (captchaEnabled) {
        if (!yandexCaptcha?.clientKey) {
            reportErrorToSentry({ error: new Error('yandex captcha client key was not configured') });
            return null;
        }

        return (
            <InvisibleSmartCaptcha
                language="ru"
                sitekey={yandexCaptcha.clientKey}
                test={testMode}
                hideShield={hideShield}
                onSuccess={handleCaptchaChallengePassed}
                visible={visible}
                onChallengeHidden={handleChallengeHidden}
                onChallengeVisible={handleChallengeVisible}
            />
        );
    }

    return null;
};
