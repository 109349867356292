const TEST_KEY = '__test';

export function hasStorage(name: 'localStorage' | 'sessionStorage') {
    try {
        const storage = window[name];
        storage.setItem(TEST_KEY, '1');
        storage.removeItem(TEST_KEY);
        return true;
    } catch (e) {
        return false;
    }
}

export const createStorage = () => {
    return Object.defineProperties(
        {},
        {
            setItem: {
                value: function (name: string, value: string) {
                    this[name] = value;
                },
            },
            getItem: {
                value: function (name: string) {
                    return Object.prototype.hasOwnProperty.call(this, name) ? this[name] : null;
                },
            },
            clear: {
                value: function () {
                    for (const key in this) {
                        delete this[key];
                    }
                },
            },
            removeItem: {
                value: function (name: string) {
                    if (Object.prototype.hasOwnProperty.call(this, name)) {
                        delete this[name];
                    }
                },
            },
        },
    );
};

export const configureBrowserStorage = () => {
    if (!hasStorage('localStorage')) {
        Object.defineProperties(window, {
            localStorage: {
                value: createStorage(),
            },
            sessionStorage: {
                value: createStorage(),
            },
        });
    }
};
