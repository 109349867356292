import React from 'react';
import { MenuLinkInterface } from '@vtblife/layout-components/types/menu-link.interface';
import { useHistory } from 'react-router-dom';

import { isAbsoluteUrl } from '../utils/is-absolute-url';

function isModifiedEvent(event: React.MouseEvent) {
    return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}

export const useHandleMenuItemClick = () => {
    const history = useHistory();
    return (event: React.MouseEvent, item: MenuLinkInterface) => {
        if (item.id && item.isSidebarEl) {
            window.dataLayer.push({
                event: 'lk_action',
                page_type: 'lk',
                section: 'menu',
                type: item.id,
            });
        }
        if (
            item.target !== '_blank' &&
            item.path &&
            !event.defaultPrevented && // onClick prevented default
            event.button === 0 && // ignore everything but left clicks
            !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
            event.preventDefault();
            if (isAbsoluteUrl(item.path)) return (window.location.href = item.path);
            return history.push(item.path);
        }
    };
};
