import React from 'react';
import { Modal, Button } from '@vtblife/uikit';

import styles from './report-modal.module.css';
import { useShellContext, useConfigContext } from '../../contexts';

const defaultMessage = `Что-то пошло не\u00a0так. Обновите страницу или зайдите позже.`;

export const ReportModal = () => {
    const { reportModal } = useShellContext();
    const { cdnUrl } = useConfigContext();
    const { email: { to = 'support@m2.ru', subject = 'Ошибка на сайте m2.ru', body = '' } = {}, message } = reportModal;
    return (
        <Modal opened={Boolean(reportModal.isOpened)} size="s" closable={false}>
            <img
                className={styles.image}
                src={`${cdnUrl}/assets/file-upload-server/632415a85d1bd46cb9f4a3a18258431d.svg`}
            />
            <div className={styles.title}>
                <Modal.Title>Ошибка</Modal.Title>
            </div>
            {message ? (
                <p className={styles.text} dangerouslySetInnerHTML={{ __html: message }}></p>
            ) : (
                <p className={styles.text}>{defaultMessage}</p>
            )}
            <div className={styles.actions}>
                <Button fullWidth onClick={() => window.location.reload()}>
                    Обновить
                </Button>
                <Button fullWidth href={`mailto:${to}?subject=${subject}&body=${body}`} variant="secondary">
                    Сообщить о проблеме
                </Button>
            </div>
        </Modal>
    );
};
