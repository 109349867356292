import { action, observable } from 'mobx';

export class ModalStore {
    @observable
    modalList: string[] = [];

    @observable
    currentOpen?: string;

    isOpen = (name: string) => this.currentOpen === name;

    @action
    openModal = (name: string) => {
        this.modalList.push(name);
        if (!this.currentOpen) {
            this.currentOpen = name;
        }
    };

    @action
    closeModal = (name: string) => {
        this.modalList = this.modalList.filter((item) => item !== name);
        this.currentOpen = this.modalList[0];
    };
}
