import { useEffect, useState } from 'react';

export const useHeaderVariant = (isSticky: boolean, offsetY = 42) => {
    const [isShort, setIsShort] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [iconTransform, setIconTransform] = useState('');

    const handleScroll = () => {
        if (!isSticky) return;

        // Safari negative scrollY value fix
        const scrollY = window.scrollY < 0 ? 0 : window.scrollY;

        if (scrollY > 0 && !isScrolled) {
            setIsScrolled(true);
        }

        if (scrollY === 0 && isScrolled) {
            setIsScrolled(false);
        }

        if (scrollY < offsetY) {
            let scl = (offsetY - scrollY) / offsetY;
            let tp = scrollY;
            if (scl < 0.6154) scl = 0.6154;
            if (tp > 34) tp = 34;
            setIconTransform(`scale(${scl}) translateY(${tp}px)`);
        }

        if (scrollY >= offsetY && !isShort) {
            setIsShort(true);
            setIconTransform('scale(0.6154) translateY(34px)');
        }

        if (scrollY < offsetY && isShort) {
            setIsShort(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShort, isScrolled, isSticky]);

    return { isShort, isScrolled, iconTransform };
};
