import { useEffect, useRef } from 'react';
import { Workbox } from 'workbox-window';

import { reportErrorToSentry } from '../utils';

export const useInitServiceWorker = () => {
    const workbox = useRef<Workbox>();

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            const wb = new Workbox('/sw.js');
            workbox.current = wb;
            wb.register().catch((err) => {
                reportErrorToSentry(err);
            });
        }
    }, []);

    return { workbox: workbox.current };
};
