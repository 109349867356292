// https://www.gatsbyjs.com/docs/global-css/
import 'normalize.css';
import '@vtblife/uikit/dist/css/grid.css';
import '@vtblife/uikit/dist/css/global.css';
import '@vtblife/uikit-theme';
import svg from '@vtblife/uikit-icons/dist/svg-inline';

import './gatsby-browser-global-css.css';
export { wrapRootElement } from './src/wrap-root';
export { wrapPageElement } from './src/wrap-page';

document.body.appendChild(svg);
