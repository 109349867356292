import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { History } from 'history';

export function synchronizeHistory(_history: History) {
    const historyStore = new RouterStore();
    const history = syncHistoryWithStore(_history, historyStore);

    return {
        store: historyStore,
        history,
    };
}
