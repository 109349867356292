import { navigate } from 'gatsby';
// eslint-disable-next-line
import type { History } from 'history';
import { globalHistory, navigate as vanillaNavigate } from '@reach/router';

declare module '@reach/router' {
    interface History {
        source: any;
    }
}

if (!globalHistory.source) {
    throw new Error(`patch-package has failed, possible version mismatch`);
}

// @reach/router: https://github.com/reach/router/blob/046949477cd8bb3a1023fd18992c04f8c1a9065c/src/lib/history.js
// react-router/history https://github.com/ReactTraining/history/blob/28c89f4091ae9e1b0001341ea60c629674e83627/packages/history/index.ts#L397
export function createHistory(): History<any> {
    return {
        push(location: any, state) {
            if (typeof location === 'string') {
                navigate(location, { state });
            } else {
                navigate(location.pathname!, { state });
            }
        },
        replace(location: any, state) {
            if (typeof location === 'string') {
                navigate(location, { state, replace: true });
            } else {
                navigate(location.pathname!, { state, replace: true });
            }
        },
        get length() {
            return globalHistory.source.history.length;
        },
        action: 'PUSH',
        get location() {
            return globalHistory.location;
        },
        go(n) {
            vanillaNavigate(n);
        },
        goBack() {
            vanillaNavigate(-1);
        },
        goForward() {
            vanillaNavigate(+1);
        },
        // @ts-expect-error
        // eslint-disable-next-line
        block(_prompt) {},
        listen(fn) {
            return globalHistory.listen(({ location, action }) => {
                fn(location, action);
            });
        },
        // @ts-expect-error
        // eslint-disable-next-line
        createHref(_location) {},
    };
}

export function createScopedHistory(routesRegex: RegExp) {
    const history = createHistory();

    const listenFn = history.listen;
    history.listen = (fn) =>
        listenFn((state, action) => {
            if (state.pathname.match(routesRegex)) {
                fn(state, action);
            }
        });

    return history;
}
