import React from 'react';
import { inject, observer } from 'mobx-react';
import { Footer as LayoutComponentsFooter } from '@vtblife/layout-components/footer/footer';
import { MenuItemComponentProps } from '@vtblife/layout-components/types/menu-item.interface';
import { Link } from 'react-router-dom';

import { FooterStore } from '../../stores/footer';

interface FooterProps {
    footerStore: FooterStore;
    isLandingWidth?: boolean;
}

const FooterComponent = ({ footerStore, isLandingWidth }: FooterProps) => {
    const { top, middleLinks, socialLinks, downloadAppQrCode, logoHref } = footerStore;

    const FooterMenuItemComponent: React.FC<MenuItemComponentProps> = (props) => {
        const { path, url, linkGenerationId, ...rest } = props;

        if (path) {
            // linkgenerationid is unknown
            // @ts-expect-error
            return <Link key={path} to={path} linkgenerationid={linkGenerationId} {...rest} />;
        }

        // linkgenerationid is unknown
        // @ts-expect-error
        // eslint-disable-next-line react/no-unknown-property
        return <a key={url} href={url} linkgenerationid={linkGenerationId} {...rest} />;
    };

    return (
        <LayoutComponentsFooter
            top={top}
            middle={middleLinks}
            socialLinks={socialLinks}
            downloadAppQrCode={downloadAppQrCode}
            logoHref={logoHref}
            isLandingWidth={isLandingWidth}
            menuItemComponent={FooterMenuItemComponent}
        />
    );
};

export const Footer = inject('footerStore', 'headerStore')(observer(FooterComponent)) as React.FC<
    Omit<FooterProps, 'footerStore'>
>;
