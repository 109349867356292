import { RoleType } from '@vtblife/event-bus-events';

export function isAllowedByPermissions(requiredPermission: string[] = [], userPermissions: string[] = []) {
    return (
        requiredPermission.length == 0 ||
        requiredPermission.every((requiredPermission) =>
            userPermissions.some((userPermission) => userPermission.startsWith(requiredPermission)),
        )
    );
}

export function isAllowedByUserRoleTypes(requiredRoleTypes: RoleType[] = [], currentRoleType?: RoleType) {
    if (!requiredRoleTypes?.length) return true;
    if (!currentRoleType) return false;

    return requiredRoleTypes.includes(currentRoleType);
}
