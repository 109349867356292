import { SidebarItem } from '@vtblife/layout-config/types';
import React, { useContext } from 'react';
import cn from 'classnames';
import Icon from '@vtblife/uikit-icons';

import { MenuItemBase } from '../../menu-item-base/menu-item-base';
import styles from './user-info-menu-item.module.css';
import { HeaderContextValue } from '../../header-context';
import { comparePathname } from '../../utils';
import { MenuLink, MenuItemProps } from '../../menu-link/menu-link';

interface UserInfoMenuItemProps extends Omit<MenuItemProps, 'item'> {
    item: SidebarItem & {
        freshCount?: number;
    };
    dataTest?: string;
    showBaseItem?: boolean;
}

const LOGOUT_BUTTON_TO = '/logout';

export const UserInfoMenuItem: React.FC<UserInfoMenuItemProps> = (props) => {
    const { logoutPath, pathname } = useContext(HeaderContextValue);
    const { path, url, icon, displayName, showInDesktopProfileMenu, id } = props.item;
    // false if not logout button
    const logoutUrl = (path === LOGOUT_BUTTON_TO || url === LOGOUT_BUTTON_TO) && logoutPath;
    const isActive = comparePathname(pathname, props.item);

    if (!props.showBaseItem) {
        return <MenuLink {...props} item={{ ...props.item, isSidebarEl: true }} />;
    }
    return (
        <MenuItemBase
            dataTest={props.dataTest}
            className={cn(styles.userInfoMenuItem, {
                [styles.userInfoMenuItemDesktop]: showInDesktopProfileMenu,
                [styles.userInfoMenuItemActive]: isActive,
            })}
            path={logoutUrl || path}
            url={logoutUrl || url}
            isActive={isActive}
            onClick={props.onClick}
            id={id}
            isSidebarEl
        >
            {icon && (
                <span className={styles.userInfoMenuItemIcon}>
                    <Icon name={icon} size="m" />
                </span>
            )}
            {displayName}
        </MenuItemBase>
    );
};
