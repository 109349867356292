import React, { FC } from 'react';
import Icon from '@vtblife/uikit-icons';

import styles from './close-button.module.css';

interface CloseButtonProps {
    onClick: () => void;
    dataTest?: string;
}

export const CloseButton: FC<CloseButtonProps> = ({ onClick, dataTest = 'close-button' }) => (
    <button className={styles.closeButton} onClick={onClick} data-test={dataTest}>
        <Icon name="times" size="m" />
    </button>
);
