import React, { ReactText, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@vtblife/uikit';

import { showToasterNotification } from '../notification-toaster/toaster-adapter/toaster-adapter';

export const OfflineEventListener = () => {
    const toastId = useRef<ReactText | undefined | null>(null);

    const handleOffline = useCallback(() => {
        toastId.current = showToasterNotification({
            type: 'info',
            message: (
                <>
                    <Typography variant="h4" bold>
                        Нет подключения к&nbsp;интернету
                    </Typography>
                    <Typography variant="primary">
                        Попробуйте обновить страницу или включить и&nbsp;выключить авиарежим
                    </Typography>
                </>
            ),
            options: {
                autoClose: false,
            },
        });
    }, []);

    const handleOnline = useCallback(() => {
        if (toastId.current) {
            toast.dismiss(toastId.current);
            toastId.current = null;
            showToasterNotification({
                type: 'info',
                message: <>Подключение к&nbsp;интернету восстановлено</>,
            });
        }
    }, []);

    useEffect(() => {
        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, [handleOffline, handleOnline]);

    return null;
};
