import { action, computed, observable } from 'mobx';
import { LayoutRoutesConfig } from 'typings/configs';

import { RootStore } from './root';

export class LayoutStore {
    rootStore: RootStore;

    @observable private layoutConfig?: LayoutRoutesConfig;

    constructor(rootStore: RootStore, initialState?: InitialState['layoutStore']) {
        this.rootStore = rootStore;
        if (initialState) {
            this.setInitialState(initialState);
        }
    }

    setInitialState(initialState: InitialState['layoutStore']) {
        Object.assign(this, initialState);
    }

    @action
    setLayoutConfig(layoutConfig: LayoutRoutesConfig) {
        this.layoutConfig = layoutConfig;
    }

    @computed
    get currentPath() {
        const location = this.rootStore.historyStore.location;
        return `${location.pathname}${location.search}`;
    }

    @computed get isInitialized() {
        return Boolean(this.layoutConfig);
    }

    @computed
    get layout() {
        const layoutRef = this.layoutConfig?.routes.find((route) => {
            const regExp = new RegExp(route.url.join('|'));
            return regExp.test(this.currentPath);
        })?.layoutRef;

        return this.layoutConfig?.layouts.find((layout) => layout.ref === layoutRef);
    }

    @computed
    get shouldDisableRegionModals() {
        return this.layout.userRegionModals?.disable;
    }

    @computed
    get hasFooter() {
        return Boolean(this.layout?.footer);
    }

    @computed
    get footerProps() {
        return this.layout?.footer;
    }

    @computed
    get hasHeader() {
        return Boolean(this.layout?.header);
    }

    @computed
    get headerProps() {
        return this.layout?.header;
    }

    @computed
    get hasSidebar() {
        return Boolean(this.layout?.sidebar);
    }

    @computed
    get sidebarProps() {
        return this.layout?.sidebar;
    }

    @computed
    get hideUseDeskRoutes() {
        return (this.layoutConfig?.hideUseDeskRoutes || []).map((route) => new RegExp(route));
    }

    @computed
    get hideCookieConsentRoutes() {
        return (this.layoutConfig?.hideCookieConsentRoutes || []).map((route) => new RegExp(route));
    }
}
