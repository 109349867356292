import React, { useCallback, useEffect, useState } from 'react';
import { EventBus } from '@vtblife/event-bus';
import { LKNewsReadEventType } from '@vtblife/event-bus-events';
import { compareAsc, isValid, parse } from 'date-fns';
import { ru } from 'date-fns/locale';

import { newsService } from '../services/news-api';
import { reportErrorToSentry } from '../shell/utils';
import { useMountedState } from '../utils';
import { FreshDot } from '../fresh-dot/fresh-dot';

export const parseDate = (date?: string) => parse(date || '', 'dd.MM.yyyy', new Date(), { locale: ru });

export const NewsContainer: React.FC<{
    isSidebarClosed?: boolean;
    variant?: React.ComponentProps<typeof FreshDot>['variant'];
}> = ({ variant, isSidebarClosed, children }) => {
    const [shouldShowCircle, setShouldShowCircle] = useState(false);
    const [shouldShowNews, setShouldShowNews] = useState(false);
    const isMounted = useMountedState();

    const checkLatestNews = useCallback(async () => {
        try {
            const { data }: { data: { news: any[] } } = await newsService.getNews();
            const news = data?.news;
            const latestDate = news?.[0]?.date;
            const latestViewedDate = localStorage.getItem('latestViewedNewsDateLK');
            if (!latestDate || !isMounted()) {
                return;
            }
            setShouldShowNews(true);
            if (!latestViewedDate) {
                setShouldShowCircle(true);
                return;
            }
            const latestParsed = parseDate(latestDate);
            const latestViewedParsed = parseDate(latestViewedDate);
            if (isValid(latestParsed) && isValid(latestViewedParsed)) {
                setShouldShowCircle(compareAsc(latestParsed, latestViewedParsed) === 1);
            }
        } catch (error) {
            reportErrorToSentry({ error });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const eventBus = EventBus.getInstance();
        const sub = eventBus.subscribe<LKNewsReadEventType, null>('lkNews:read', () => {
            setShouldShowCircle(false);
        });
        checkLatestNews();
        return () => {
            sub.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!shouldShowNews) {
        return null;
    }

    return (
        <FreshDot isCircleVisible={shouldShowCircle} variant={variant} isSidebarClosed={isSidebarClosed}>
            {children}
        </FreshDot>
    );
};
