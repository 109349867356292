import React from 'react';
import { Link } from '@vtblife/uikit';

import { useConfigContext } from '../../shell/contexts';
import { transformMardownLinks } from '../../utils/transform-mardown-links';
import styles from './bottom.module.css';

export const Bottom = () => {
    const { ITRequirements, copyright } = useConfigContext();

    const result =
        ITRequirements &&
        transformMardownLinks({
            str: ITRequirements,
            mapping: ({ href, text }) => {
                return (
                    <Link key={href} href={href} target="blank">
                        <span className={styles.requirementsLink}>{text}</span>
                    </Link>
                );
            },
        });

    return (
        <div>
            {result && <div className={styles.requirements}>{result}</div>}
            <div className={styles.copyright}>{copyright}</div>
        </div>
    );
};
