import { useEffect } from 'react';

import { setLastAuth } from '../helpers';
import { useAuthorizeContext } from '../shell';

export const useSyncAuthState = () => {
    const { user } = useAuthorizeContext();
    useEffect(() => {
        setLastAuth({
            username: user?.username,
            isAuthorized: Boolean(user?.isAuthenticated),
            currentRoleType: user?.currentRoleType,
            userId: user?.userId,
        });
    }, [user]);
};
