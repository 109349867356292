import React, { FC, Suspense } from 'react';
import { MicroBundle } from '@vtblife/micro-frontend-core';
import { History } from 'history';
import { observer } from 'mobx-react';

import { BundleTreeStore } from '../../stores/bundle-tree';
import { ConfigStore } from '../../stores/config';
import { UserStore } from '../../stores/user';
import { ErrorBoundary } from '../error-boundary/error-boundary';
import { AUTH_APP_NAME } from '../../constants';

interface Props {
    initialState: InitialState;
    history: History;
    userStore: UserStore;
    configStore: ConfigStore;
    bundleTreeStore: BundleTreeStore;
}

export const AuthApp: FC<Props> = observer(({ initialState, history, bundleTreeStore, configStore, userStore }) => {
    if (!userStore.needLoadAuth) return null;

    const route = bundleTreeStore.bundleTreeRoutes.find((route) => route.applicationName === AUTH_APP_NAME);
    if (!route) return null;

    return (
        <Suspense fallback={<></>}>
            {/* @ts-expect-error */}
            <ErrorBoundary
                extraInfo={{
                    user: userStore.toJS(),
                    config: configStore.toJS(),
                    bundleTreeRoutes: bundleTreeStore.bundleTreeRoutes,
                }}
            >
                <MicroBundle
                    name={route.applicationName}
                    path={route.assetsUrl || configStore.getApplicationPath(route.applicationName)}
                    basePath={route.path}
                    history={history}
                    authInfo={userStore.toJS()}
                    config={configStore.toJS()}
                    initialState={initialState}
                />
            </ErrorBoundary>
        </Suspense>
    );
});
