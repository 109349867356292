import React from 'react';

export const PHONE = '+7 495 136‑28‑18';
export const WORK_TIME = 'Круглосуточно';
export const FEEDBACK_SURVEY_LINK = 'https://anketolog.ru/s/626678/mScHs49X';
export const TRACE_HEADER_NAME = 'x-trace-id';
export const CALL_CENTER_EMAIL = 'cc@m2.ru';
export const USER_REGION_ID_DETECTED_COOKIE = 'user_region_id_detected';
export const USER_REGION_ID_SELECTED_COOKIE = 'user_region_id_selected';
export const USER_REGION_ID_SELECTED_COOKIE_SEPARATOR = '-';

export const MESSAGES = {
    offline: 'Отсутствует соединение с интернетом. Подключитесь к интернету и попробуйте ещё раз',
    doNotReloadPage: (
        <>
            Чтобы не&nbsp;потерять введенные данные, не&nbsp;обновляйте страницу, подождите несколько минут
            и&nbsp;попробуйте повторить действие снова
        </>
    ),
    reloadPage: <>Подождите несколько минут и&nbsp;перезагрузите страницу</>,
    needScreenshot: (
        <>
            Пожалуйста, сделайте скриншот страницы и&nbsp;отправьте его в&nbsp;службу поддержки на&nbsp;
            <a style={{ fontWeight: 'bold' }} href={`mailto:${CALL_CENTER_EMAIL}`}>
                {CALL_CENTER_EMAIL}
            </a>
        </>
    ),
    serviceDown: 'Сервис недоступен',
    forbiddenAccess: 'Доступ запрещён',
    defaultError: 'Произошла ошибка, попробуйте позже',
    unknownError: 'Произошла ошибка',
};

export const HEADER_NAMES = {
    grpcStatus: 'grpc-status',
    grpcMessage: 'grpc-message',
    date: 'date',
    varitiCcr: 'x-variti-ccr',
    traceId: 'x-trace-id',
    cfRay: 'cf-ray',
};

export enum ErrorType {
    Other = 'other',
    Offline = 'offline network',
    Forbidden = 403,
    Unauthorized = 401,
}

export const ANONYM_USER_PREFIX = 'anonym#';

export * from './domains';

export enum LinkGenerationId {
    ClassifiedBuy = 'classified:buy',
    ClassifiedRent = 'classified:rent',
    NewBuildings = 'new-buildings',
    ClassifiedBuySecond = 'classified:buy:second',
    ClassifiedBuyOneRoom = 'classified:buy:one-room',
}

export enum HeaderVariant {
    Default = 'default',
}
