export function transformMardownLinks({
    str,
    mapping,
}: {
    str: string;
    mapping: ({ text, href }: { text: string; href: string }) => JSX.Element | string;
}) {
    // eslint-disable-next-line no-useless-escape
    const match = str.matchAll(/\[([^\[\]]*)\]\((.*?)\)/g);

    const reverseArr: RegExpMatchArray[] = [];
    for (const item of match) {
        reverseArr.unshift(item);
    }

    let text = str;
    const result: (string | JSX.Element)[] = [];

    for (const item of reverseArr) {
        const textLength = item[0].length;
        const startIndex = +item.index!;
        const endIndex = startIndex + textLength;

        const textItem = text.slice(endIndex);
        if (textItem) {
            result.push(textItem);
        }

        result.push(mapping({ text: item[1], href: item[2] }));

        text = text.slice(0, startIndex);
    }
    if (text) {
        result.push(text);
    }

    return result.reverse();
}
