import { useEffect, useState } from 'react';
import { CheckSessionExpired } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

import { useAuthorizeContext } from '../shell';

export const useCheckAuthState = (disableCheckAuthState: boolean) => {
    const { isAuthorized } = useAuthorizeContext();
    const [, setPageHidden] = useState(false);

    const handleVisibilityChange = () => {
        setPageHidden((prevPageHiddenState) => {
            // если страница сейчас активна, в предыдущем состоянии была не в фокусе и пользователь авторизован
            if (!document.hidden && prevPageHiddenState && isAuthorized && !disableCheckAuthState) {
                const checkIfSessionExpiredEvent: CheckSessionExpired = {
                    type: 'auth:checkIfSessionExpired',
                    category: 'simple',
                    data: {},
                };
                EventBus?.getInstance()?.publish(checkIfSessionExpiredEvent);
            }
            return document.hidden;
        });
    };

    useEffect(() => {
        if (typeof document.hidden !== 'undefined') {
            const visibilityChangeEventName = 'visibilitychange';
            document.addEventListener(visibilityChangeEventName, handleVisibilityChange);
            return () => {
                document.removeEventListener(visibilityChangeEventName, handleVisibilityChange);
            };
        }
        return () => {
            // do nothing;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthorized, disableCheckAuthState]);
};
