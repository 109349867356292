import React from 'react';
import cn from 'classnames';

import styles from './footer.module.css';
import { Top } from './top/top';
import { Middle } from './middle/middle';
import { Bottom } from './bottom/bottom';
import { MenuItemComponentProps } from '../types/menu-item.interface';
import { FooterLink, FooterMiddleCol, FooterPhoneType, FooterSocialLink } from '../types/footer.interface';

export type FooterProps = {
    menuItemComponent: React.FC<MenuItemComponentProps>;
    logoHref?: string;
    top: FooterLink[][];
    middle: FooterMiddleCol[];
    phone?: FooterPhoneType;
    isLandingWidth?: boolean;
    downloadAppQrCode: string;
    socialLinks: FooterSocialLink[];
};

export const Footer: React.FC<FooterProps> = ({ isLandingWidth, logoHref = '/', ...props }) => {
    return (
        <footer id="footer" className={styles.footer}>
            <div className={cn(styles.content, { [styles.contentLanding]: isLandingWidth })}>
                <Top logoHref={logoHref} {...props} />
                <Middle {...props} />
                <Bottom />
            </div>
        </footer>
    );
};
