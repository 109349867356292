import { RoleType, User } from '@vtblife/event-bus-events';

export const EMPTY_USER_INFO: User = {
    roles: [],
    permissions: [],
    userId: '',
    currentRole: '',
    currentRoleType: '' as RoleType,
    companyId: null,
    isAuthenticated: false,
    username: '',
    currentRoleDisplayName: '',
    companyBranchId: null,
};
