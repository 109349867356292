import React, { useContext, useEffect } from 'react';
import cn from 'classnames';

import styles from './header-layout.module.css';
import { MenuItemBase } from '../menu-item-base/menu-item-base';
import { Tabs } from '../tabs/tabs';
import { InfoMenu } from '../info-menu/info-menu';
import { ChangeRegionButton } from '../change-region-button/change-region-button';
import { GEO_CONFIRM_HEADER, sendAnalyticsEvent } from '../../analytics';
import { PersonalButtons } from '../personal-buttons/personal-buttons';
import { HeaderContextValue } from '../header-context';
import { CloseButton } from '../close-button/close-button';
import { BurgerButton } from '../burger-button/burger-button';
import { preventBodyScrollOverlayOpened } from '../../utils';
import { useHeaderVariant } from '../hooks';
import { Logo } from '../logo/logo';
import { LogoVariant } from '../logo/images';
import { useAuthorizeContext, useUserRegionContext } from '../../shell';
import { OfferPlacementLink } from '../offer-placement-link/offer-placement-link';
import { getOfferPlacementButtonUrl } from '../utils';

export interface HeaderLayoutProps {
    className?: string;
    cdnUrl: string;
    logoHref?: string;
    logoVariant: LogoVariant;
    isBordered?: boolean;
    isSticky?: boolean;
    isFullWidth?: boolean;
    isTransparent?: boolean;
}

export const HeaderLayout: React.FC<HeaderLayoutProps> = ({
    className,
    logoHref = '/',
    logoVariant,
    isSticky,
    isBordered,
    isFullWidth,
    isTransparent,
}) => {
    const { isMenuOpened, toggleMenu, isPersonalButtonsVisible, isInfoMenuVisible, personalButtons } =
        useContext(HeaderContextValue);
    const { isAuthorized } = useAuthorizeContext();
    const { isShort, isScrolled, iconTransform } = useHeaderVariant(!!isSticky);
    const { userRegionName, showSelectRegionModal } = useUserRegionContext();

    const handleChangeRegionButtonClick = () => {
        showSelectRegionModal?.();
        sendAnalyticsEvent(GEO_CONFIRM_HEADER);
    };

    useEffect(() => {
        preventBodyScrollOverlayOpened(isMenuOpened);
    }, [isMenuOpened]);

    return (
        <header
            className={cn(styles.headerLayout, className, {
                [styles.headerLayoutShort]: isShort,
                [styles.headerLayoutSticky]: isSticky && isScrolled,
                [styles.headerLayoutOpened]: isMenuOpened,
                [styles.headerLayoutBordered]: isBordered,
                [styles.headerLayoutTransparent]: isTransparent,
            })}
            data-test={isMenuOpened ? 'header-opened' : 'header-closed'}
        >
            <div
                className={cn(styles.headerLayoutContent, {
                    [styles.headerLayoutContentFull]: isFullWidth,
                })}
            >
                <div className={styles.headerLayoutLeft}>
                    <div className={styles.headerLayoutBurger}>
                        <BurgerButton onClick={toggleMenu} dataTest="header-burger-button" />
                    </div>
                    <div className={styles.headerLayoutLogo}>
                        <MenuItemBase path={logoHref} url={logoHref} className={styles.headerLayoutLogoIcon}>
                            <Logo variant={logoVariant} transform={iconTransform} />
                        </MenuItemBase>
                    </div>
                    <div className={cn(styles.headerLayoutMenu, { [styles.headerLayoutMenuOpened]: isMenuOpened })}>
                        <div className={styles.headerLayoutCloseMenuButton}>
                            <CloseButton onClick={toggleMenu} dataTest="header-close-button" />
                        </div>
                        <div className={styles.headerLayoutChangeRegionButton}>
                            <ChangeRegionButton onClick={handleChangeRegionButtonClick} regionName={userRegionName} />
                        </div>
                        <div className={styles.headerLayoutInfoMenu}>{isInfoMenuVisible && <InfoMenu />}</div>
                        <Tabs />
                    </div>
                </div>
                <div className={styles.headerLayoutPersonalButtonsMobile}>
                    {isPersonalButtonsVisible && (
                        <>
                            <PersonalButtons />
                            <OfferPlacementLink
                                url={getOfferPlacementButtonUrl(isAuthorized, personalButtons?.offerPlacement)}
                            />
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};
