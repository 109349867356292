import React, { useContext } from 'react';
import cn from 'classnames';

import styles from './tabs.module.css';
import { MenuLink } from '../menu-link/menu-link';
import { MenuItemBase } from '../menu-item-base/menu-item-base';
import { PartnersButton } from '../partners-button/partners-button';
import { TabsIds } from '../constants';
import { getOfferPlacementButtonUrl } from '../utils';
import { HeaderContextValue } from '../header-context';
import { useAuthorizeContext } from '../../shell';
import { sendAnalyticsEvent } from '../../analytics/send-analytics-event';
import { useActiveTab } from '../hooks';

export const getActiveClassname = (activeTabId?: string) => {
    switch (activeTabId) {
        case TabsIds.REALTORS:
            return styles.tabsItemsRieltors;
        case TabsIds.DEVELOPERS:
            return styles.tabsItemsDevelopers;
    }
};

export const Tabs = () => {
    const { isAuthorized } = useAuthorizeContext();
    const { items, partners, activePartner, onPartnerClick, pathname, personalButtons, isTransparent, isTablet } =
        useContext(HeaderContextValue);
    const activeTab = useActiveTab();
    const activeTabId = activeTab?.id;
    const currentTabMenu = activeTab?.dropdownMenu;
    const offerPlacementUrl = getOfferPlacementButtonUrl(isAuthorized, personalButtons?.offerPlacement);

    return (
        <div className={styles.tabs} data-test="header-tabs">
            <div className={cn(styles.tabsItems, getActiveClassname(activeTabId))}>
                {items?.map((tab) => (
                    <div className={styles.tabsItemsContainer} key={tab.id} data-test="header-tab-container">
                        <MenuItemBase
                            dataTest="header-tab-base"
                            className={cn(styles.tabItem, {
                                [styles.tabItemActive]: tab.id === activeTabId,
                                [styles.tabItemTransparent]: isTransparent,
                            })}
                            path={tab.path}
                            url={tab.url}
                            onClick={() => {
                                sendAnalyticsEvent({
                                    event: 'header_click',
                                    user_type: activeTab,
                                    product_type: tab.displayName,
                                });
                            }}
                        >
                            {tab.displayName}
                        </MenuItemBase>
                        {activeTabId === tab.id && (
                            <div className={styles.tabsItemsContent}>
                                {activeTabId !== TabsIds.DEVELOPERS && offerPlacementUrl && (
                                    <div
                                        className={cn(styles.tabsContentItem, styles.tabsContentItemMobile)}
                                        data-test="header-tab-item"
                                    >
                                        <MenuLink
                                            item={{
                                                id: offerPlacementUrl,
                                                displayName: 'Новое объявление',
                                                url: offerPlacementUrl,
                                            }}
                                            isOpenByDefault={false}
                                        />
                                    </div>
                                )}
                                {currentTabMenu?.map((item) => (
                                    <div
                                        key={item.displayName}
                                        className={styles.tabsContentItem}
                                        data-test="header-tab-item"
                                    >
                                        <MenuLink
                                            item={item}
                                            pathname={pathname}
                                            isMainMenu
                                            isOpenByDefault={!isAuthorized || !isTablet}
                                        />
                                    </div>
                                ))}
                                {activeTabId === TabsIds.DEFAULT && partners && (
                                    <div className={styles.tabsContentItem} data-test="header-tab-item">
                                        <PartnersButton
                                            partners={partners}
                                            activePartner={activePartner}
                                            onPartnerClick={onPartnerClick}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
