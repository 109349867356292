import React, { useCallback } from 'react';
import { Button } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';
import cn from 'classnames';
import useTransitionState from '@vtblife/uikit/dist/hooks/use-transition-state';

import styles from './detect-region-modal.module.css';

const closingTimeout = 400; // ms

interface DetectRegionContentProps {
    regionName: string;
    onAccept: () => void;
    onDecline: () => void;
}

const DetectRegionContent: React.FC<DetectRegionContentProps> = ({ regionName, onAccept, onDecline }) => {
    return (
        <div>
            <div className={styles.regionLabel}>Ваш регион</div>
            <div className={styles.regionValue}>{regionName}?</div>
            <div className={styles.regionControls}>
                <Button onClick={onAccept} size="s" dataTest="detect-region-modal-accept">
                    Да, верно
                </Button>
                <span className={styles.regionControlsLink}>
                    <Button
                        size="s"
                        variant="transparent"
                        onClick={onDecline}
                        fullWidth={true}
                        dataTest="detect-region-modal-decline"
                    >
                        Нет, другой
                    </Button>
                </span>
            </div>
        </div>
    );
};

interface DetectRegionModalProps extends DetectRegionContentProps {
    regionName: string;
    opened: boolean;
    onClose: () => void;
}

export const DetectRegionModal: React.FC<DetectRegionModalProps> = ({
    regionName,
    opened,
    onClose,
    onAccept,
    onDecline,
}) => {
    const [state, close] = useTransitionState(opened, { duration: closingTimeout });
    const handleClose = useCallback(async () => {
        if (state !== 'show') return;

        await close();
        onClose?.();
    }, [onClose, state, close]);

    return (
        <div
            className={cn(styles.detectRegionModal, {
                [styles.detectRegionModalIn]: state === 'show',
                [styles.detectRegionModalOut]: state === 'exit',
            })}
            data-test="detect-region-modal"
        >
            <div className={styles.detectRegionModalOverlay} />
            <div className={styles.detectRegionModalContent}>
                <div className={styles.detectRegionModalClose} onClick={handleClose}>
                    <Icon name="times" size="s" />
                </div>
                <DetectRegionContent regionName={regionName} onAccept={onAccept} onDecline={onDecline} />
            </div>
        </div>
    );
};
