import { AppEnvironment } from '@vtblife/event-bus-events';

// TODO: @ereminee добавить конфиг в env
export const getFirebaseConfig = (appEnv: AppEnvironment) => {
    if (appEnv === 'production') {
        return {
            apiKey: 'AIzaSyB8lTfF-gqFhq1zyglpytpCcC6xG0GbS2M',
            authDomain: 'm2-web-prod.firebaseapp.com',
            projectId: 'm2-web-prod',
            storageBucket: 'm2-web-prod.appspot.com',
            messagingSenderId: '778643000064',
            appId: '1:778643000064:web:83b495e0a8bd85f10741e5',
        };
    }
    return {
        apiKey: 'AIzaSyDiztlV0cEN8KexD3Sh1R3CfWZ4CSRd0LY',
        authDomain: 'm2-web-test.firebaseapp.com',
        projectId: 'm2-web-test',
        storageBucket: 'm2-web-test.appspot.com',
        messagingSenderId: '551919811727',
        appId: '1:551919811727:web:21384f491cfafb29dc44fa',
        measurementId: 'G-YF9CY37NN8',
    };
};
