import React from 'react';
import { User } from '@vtblife/event-bus-events';

import cn from 'classnames';

import styles from './user-info-menu-content-profile.module.css';
import { formatPhone, isPhoneNumberString, getProfileHeaderByRoleType } from '../../../utils';

interface UserInfoMenuContentProfileProps {
    user: User;
}

export const UserInfoMenuContentProfile: React.FC<UserInfoMenuContentProfileProps> = ({ user }) => {
    const username = user.username && isPhoneNumberString(user.username) ? formatPhone(user.username) : user.username;

    return (
        <div className={styles.userInfoMenuContentProfileContainer}>
            <span className={styles.userInfoMenuContentProfile}>{username}</span>
            <span className={cn(styles.userInfoMenuContentProfile, styles.userInfoMenuContentProfileSeparator)}>
                {` · `}
            </span>
            <span className={styles.userInfoMenuContentProfile}>
                {getProfileHeaderByRoleType(user.currentRoleType)}
            </span>
        </div>
    );
};
