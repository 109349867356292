import React, { useContext, FC } from 'react';
import { Tooltip, useTooltip } from '@vtblife/uikit';
import cn from 'classnames';

import './offer-placement-link.module.css';
import { MenuItemBase } from '../menu-item-base/menu-item-base';
import PlusCircleIcon from '../images/plus-circle.component.svg';
import { SvgIcon } from '../svg-icon/svg-icon';
import { HeaderContextValue } from '../header-context';

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    offerPlacementLinkDesktopMobile: 'offerPlacementLinkDesktopMobile',
    offerPlacementLink: 'offerPlacementLink',
    offerPlacementLinkItem: 'offerPlacementLinkItem',
    offerPlacementLinkItemTransparent: 'offerPlacementLinkItemTransparent',
    offerPlacementLinkText: 'offerPlacementLinkText',
    offerPlacementLinkTablet: 'offerPlacementLinkTablet',
    offerPlacementLinkTabletVisible: 'offerPlacementLinkTabletVisible',
};

type Props = {
    url?: string;
};

export const OfferPlacementLink: FC<Props> = ({ url }) => {
    const { to, registerControl } = useTooltip();
    const { isPartnerLogoVariant, isTransparent } = useContext(HeaderContextValue);
    if (!url) return null;

    return (
        <MenuItemBase url={url} className={styles.offerPlacementLink}>
            {!isPartnerLogoVariant && (
                <span className={styles.offerPlacementLinkDesktopMobile}>
                    <span
                        className={cn(styles.offerPlacementLinkItem, {
                            [styles.offerPlacementLinkItemTransparent]: isTransparent,
                        })}
                    >
                        <span>
                            <SvgIcon iconComponent={<PlusCircleIcon />} />
                        </span>
                        <span className={styles.offerPlacementLinkText}>Новое объявление</span>
                    </span>
                </span>
            )}
            <span
                className={cn(styles.offerPlacementLinkTablet, {
                    [styles.offerPlacementLinkTabletVisible]: isPartnerLogoVariant,
                })}
                ref={registerControl}
            >
                <span
                    className={cn(styles.offerPlacementLinkItem, {
                        [styles.offerPlacementLinkItemTransparent]: isTransparent,
                    })}
                >
                    <span>
                        <SvgIcon iconComponent={<PlusCircleIcon />} />
                    </span>
                    <Tooltip to={to} placement="body" direction="down">
                        Новое объявление
                    </Tooltip>
                </span>
            </span>
        </MenuItemBase>
    );
};
