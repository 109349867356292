import { HttpTransport, Transport } from './http-transport';

class AuthApiService {
    transport: Transport;

    constructor(name = 'auth') {
        this.transport = new HttpTransport({
            baseURL: `/api/${name}-bff/`,
        });
    }

    logout(): Promise<any> {
        return this.transport.post('/api/sign-out', {});
    }
}

export const authApiService = new AuthApiService();
