import React, { useEffect, useMemo } from 'react';
import { HeaderTab, LoginPath, LoginRole, Partners, Sidebar } from '@vtblife/layout-config/types';

import { useMedia } from 'react-use';

import { HeaderContext, HeaderContextValue } from './header-context';
import { getActiveTabId, shouldShowPersonalButtons } from './utils';
import { HeaderPersonalButton } from '../types/header.interface';
import { MenuLinkInterface } from '../types/menu-link.interface';
import { HeaderLayout } from './header-layout/header-layout';
import { LogoVariant } from './logo/images';
import { TabsIds } from './constants';
import { useHeaderVariant, useMenuToggle } from './hooks';
import { useActiveRegionPartner } from '../hooks/use-active-region-partner';
import { MOBILE_WIDE_QUERY } from '../helpers';

export interface HeaderProps {
    cdnUrl: string;
    logoHref?: string;
    logoVariant?: LogoVariant;
    sticky?: boolean;
    hideElementsDependOnAuthState?: boolean;
    isFullWidth?: boolean;
    isColored?: boolean;
    isBordered?: boolean;
    isTransparent?: boolean;
    items: HeaderTab[];
    pathname?: string;
    authorizedItems?: Sidebar;
    roleType?: string;
    onMenuItemClick?: (e: React.MouseEvent, item: MenuLinkInterface) => void;
    className?: string;
    logoutPath?: string;
    personalAreaPath?: string;
    loginText?: string;
    mobileLoginText?: string;
    onPartnerClick?: (name: string) => void;
    partners?: Partners;
    activePartner?: string;
    personalButtons?: Record<string, HeaderPersonalButton>;
    loginPaths: Record<LoginRole, LoginPath>;
}

export const Header: React.FC<HeaderProps> = (props) => {
    const { isMenuOpened, setIsMenuOpened } = useMenuToggle(false);
    const isTablet = useMedia(MOBILE_WIDE_QUERY);

    const {
        sticky,
        isFullWidth,
        hideElementsDependOnAuthState,
        pathname,
        isBordered,
        isTransparent,
        items,
        partners,
        activePartner,
        onPartnerClick,
        authorizedItems,
        onMenuItemClick,
        cdnUrl,
        logoHref = '/',
        logoVariant = 'm2',
        roleType,
        className,
        logoutPath = '',
        loginText = 'Войти',
        mobileLoginText = loginText,
        loginPaths,
        personalAreaPath = '/personal-area',
        personalButtons,
    } = props;

    const loginPath =
        (getActiveTabId(items, pathname) === TabsIds.DEVELOPERS
            ? loginPaths?.partner?.url
            : loginPaths?.default?.url) || '/login/';

    const isInfoMenuVisible = !hideElementsDependOnAuthState;
    const isPersonalButtonsVisible = shouldShowPersonalButtons({
        currentRoleType: roleType,
        pathname,
        items,
    });
    const { isShort } = useHeaderVariant(!!sticky);

    const activeRegionPartner = useActiveRegionPartner({
        partners,
    });

    const contextValue = useMemo(
        (): HeaderContext => ({
            items,
            authorizedItems,
            isInfoMenuVisible,
            isPersonalButtonsVisible,
            personalButtons,
            partners,
            activePartner,
            activeRegionPartner,
            onPartnerClick,
            pathname,
            roleType,
            loginText,
            mobileLoginText,
            loginPath,
            closeMenu: () => setIsMenuOpened(false),
            setIsMenuOpened: setIsMenuOpened,
            toggleMenu: () => setIsMenuOpened(!isMenuOpened),
            logoutPath,
            personalAreaPath,
            isMenuOpened,
            onMenuItemClick,
            isUserInfoMenuOpened: true,
            isPartnerLogoVariant: logoVariant !== 'm2',
            isTransparent: isTransparent && !isShort,
            isTablet,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            isMenuOpened,
            isShort,
            logoutPath,
            loginPath,
            personalAreaPath,
            onMenuItemClick,
            authorizedItems,
            logoVariant,
            activePartner,
            activeRegionPartner,
            isTablet,
        ],
    );

    useEffect(() => {
        const headEl = document.querySelector('head');
        const metaThemeColorEl = headEl?.querySelector('meta[name="theme-color"]');
        if (isMenuOpened) {
            if (metaThemeColorEl) {
                metaThemeColorEl.setAttribute('content', '#F3F1F8');
            } else {
                const newMetaThemeColorEl = document.createElement('meta');
                newMetaThemeColorEl.setAttribute('name', 'theme-color');
                newMetaThemeColorEl.setAttribute('content', '#F3F1F8');
                headEl?.appendChild(newMetaThemeColorEl);
            }
        } else {
            metaThemeColorEl?.remove();
        }
    }, [isMenuOpened]);

    return (
        <HeaderContextValue.Provider value={contextValue}>
            <HeaderLayout
                cdnUrl={cdnUrl}
                logoHref={logoHref}
                logoVariant={logoVariant}
                className={className}
                isSticky={sticky}
                isFullWidth={isFullWidth}
                isBordered={isBordered}
                isTransparent={isTransparent}
            />
        </HeaderContextValue.Provider>
    );
};
