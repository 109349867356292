import React, { FC, AnchorHTMLAttributes, MouseEvent } from 'react';
import cn from 'classnames';

import styles from './common.module.css';

type FooterLinkProps = AnchorHTMLAttributes<HTMLLinkElement> & {
    component: React.FC<AnchorHTMLAttributes<HTMLLinkElement> & { path?: string; url?: string }>;
    target?: string;
    to?: string;
    href?: string;
    mobileUrl?: string;
    cdn?: boolean;
    cdnUrl?: string;
    analytics?: string;
    className?: string;
};

export const FooterLink: FC<FooterLinkProps> = ({
    component: Component,
    cdn,
    cdnUrl,
    to,
    href,
    onClick,
    className,
    analytics,
    ...rest
}) => {
    const prefix = cdn ? cdnUrl : '';
    const clickHandler = (e: MouseEvent<HTMLLinkElement>) => {
        analytics && window.dataLayer.push({ event: analytics });
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <Component
            {...rest}
            path={to}
            url={`${prefix}${href}`}
            onClick={clickHandler}
            className={cn(className, styles.link)}
        />
    );
};
