import { NotificationPayload, NotificationContentOptions } from '@vtblife/event-bus-events';
import { AxiosResponse, AxiosRequestConfig, AxiosError } from '@vtblife/axios';
import isValid from 'date-fns/isValid';
import { ReactNode } from 'react';

import { MESSAGES, ErrorType, HEADER_NAMES } from '../../constants';

/** grpc-like ServiceError */
export interface ServiceError extends Error {
    code?: number;
    metadata?: Record<string, any>;
    details?: string;
    message: string;
}

export const parseErrorType = (status?: number) => {
    if (!navigator.onLine) {
        return ErrorType.Offline;
    }
    if (status === 403) {
        return ErrorType.Forbidden;
    }
    if (status === 401) {
        return ErrorType.Unauthorized;
    }
    return ErrorType.Other;
};

export const getTrivialErrorTypesMessage = (errorType?: ErrorType): JSX.Element | string => {
    if (errorType === ErrorType.Offline || !navigator.onLine) {
        return MESSAGES.offline;
    }
    if (errorType === ErrorType.Forbidden) {
        return MESSAGES.forbiddenAccess;
    }
    return MESSAGES.defaultError;
};

const isServiceCrashed = (response?: AxiosResponse) => {
    const is500 = response?.status === 500;
    const grpcStatus = response?.headers[HEADER_NAMES.grpcStatus];
    const grpcMessage = response?.headers[HEADER_NAMES.grpcMessage];

    const hasNoGrpcHeaders = grpcStatus === undefined && grpcMessage === undefined;

    // Если Envoy недоступен и сервис лежит, мы получаем 500 без grpc-* заголовков.
    // Гипотеза: Если нам пришла 500 без grpc-*, то сервис лежит.
    // Если такие ошибки будут приходить и от работающего сервиса, нужно будет думать дальше, как их отличать.
    return is500 && hasNoGrpcHeaders;
};

const isGrpcServiceError = (error: any) => {
    if (typeof error.code !== 'number') {
        return false;
    }
    return typeof error.details === 'string' || typeof error.message === 'string' || typeof error.metadata === 'object';
};

const extractAxiosErrorMotives = (response?: AxiosResponse, config?: AxiosRequestConfig) => {
    const headers = response?.headers || {};
    if (isServiceCrashed(response)) {
        if (config?.method && ['get', 'delete'].includes(config.method.toLowerCase())) {
            return {
                message: MESSAGES.reloadPage,
                title: MESSAGES.serviceDown,
            };
        } else {
            return {
                message: MESSAGES.doNotReloadPage,
                title: MESSAGES.serviceDown,
            };
        }
    }
    const isVaritiBug = headers[HEADER_NAMES.varitiCcr] && response?.status === 502;
    if (isVaritiBug) {
        return {
            message: MESSAGES.needScreenshot,
            title: MESSAGES.unknownError,
            shouldRenderTechDetails: true,
        };
    }
    return { title: MESSAGES.unknownError };
};

const extractAxiosErrorData = (response?: AxiosResponse<{ error?: any }>) => {
    const headers = response?.headers || {};
    const traceId = headers[HEADER_NAMES.traceId] as string;
    const varitiCcr = headers[HEADER_NAMES.varitiCcr] as string;
    const cfRay = headers[HEADER_NAMES.cfRay] as string;
    const headersDate = new Date(headers[HEADER_NAMES.date]);
    const date = isValid(headersDate) ? headersDate : new Date();
    const grpcStatus = Number(headers[HEADER_NAMES.grpcStatus] || '');
    const httpStatus = response?.status && Number(response?.status);
    const grpcMessage = headers[HEADER_NAMES.grpcMessage] && decodeURIComponent(headers[HEADER_NAMES.grpcMessage]);
    const httpMessage = response?.statusText || response?.data?.error;
    const reasonMessage = grpcMessage || httpMessage;
    const requestUri = response?.config?.url;
    const location = window.location?.toString();
    const userAgent = navigator?.userAgent;
    const parsedType = parseErrorType(httpStatus);

    return {
        traceId,
        grpcStatus,
        httpStatus,
        date,
        varitiCcr,
        cfRay,
        reasonMessage,
        requestUri,
        location,
        userAgent,
        parsedType,
    };
};

const extractErrorData = (error: Error) => {
    let data: { statusCode?: number; status?: number; error?: string; path?: string; message?: string } = {};
    try {
        const parsedMessage = JSON.parse(error.message);
        data = parsedMessage;
    } catch (err) {
        data = { message: error.message };
    }
    const status = data.statusCode || data.status;
    const httpStatus = status && Number(status);
    const message = data.message || data.error;
    const reasonMessage = message?.startsWith('<html>') ? 'some HTML (removed)' : message;
    return {
        httpStatus,
        location: window.location?.toString(),
        userAgent: navigator?.userAgent,
        reasonMessage,
        requestUri: data.path,
        date: new Date(),
        parsedType: parseErrorType(httpStatus),
    };
};

const extractGrpcServiceErrorData = (error: ServiceError & { status?: number }) => {
    const headers = error.metadata;
    const grpcStatus =
        (error.code && Number(error.code)) ||
        (headers?.[HEADER_NAMES.grpcStatus] && Number(headers?.[HEADER_NAMES.grpcStatus]));
    const httpStatus = error.status;
    const reasonMessage = (error.details || error.message || headers?.[HEADER_NAMES.grpcMessage]) as string;
    const headersDate = headers?.[HEADER_NAMES.date] && new Date(headers?.[HEADER_NAMES.date]);
    const date = isValid(headersDate) ? headersDate : new Date();
    const traceId = headers?.[HEADER_NAMES.traceId] as string;
    return {
        httpStatus,
        grpcStatus,
        traceId,
        location: window.location?.toString(),
        userAgent: navigator?.userAgent,
        reasonMessage,
        date,
        parsedType: parseErrorType(httpStatus),
    };
};

const extractNotificationPayloadErrorData = (payload: NotificationPayload) => {
    return {
        message: payload.message,
        title: payload.options?.title || '',
        footerFn: payload?.options?.footerFn,
        additionalMessage: payload.options?.additionalMessage,
    };
};

export const mergeErrorDataWithNotificationPayload = (
    extractedData: ComprehensiveErrorData,
    payload: NotificationPayload,
) => {
    const { title, message, additionalMessage, footerFn } = extractNotificationPayloadErrorData(payload);
    if (!extractedData.ui) {
        extractedData.ui = {};
    }
    if (message) {
        extractedData.ui.message = message as JSX.Element;
    }
    if (title) {
        extractedData.ui.title = title;
    }
    if (footerFn) {
        extractedData.ui.footerFn = footerFn;
    }
    if (additionalMessage) {
        extractedData.ui.additionalMessage = additionalMessage;
    }
    return extractedData;
};

export const extractErrorDataByType = (rawError?: any) => {
    let extractedData = {} as ComprehensiveErrorData;
    if (rawError) {
        if ((rawError as AxiosError).isAxiosError) {
            const { response, config } = rawError;
            extractedData = {
                data: extractAxiosErrorData(response),
                ui: extractAxiosErrorMotives(response, config),
            };
        } else if (isGrpcServiceError(rawError)) {
            extractedData = {
                data: extractGrpcServiceErrorData(rawError),
                ui: { title: MESSAGES.unknownError },
            };
        } else if (rawError instanceof Error) {
            extractedData = {
                data: extractErrorData(rawError),
                ui: { title: MESSAGES.unknownError },
            };
        } else if (typeof rawError === 'string') {
            extractedData = { data: { reasonMessage: rawError } };
        }
    }
    if (!extractedData.ui?.message) {
        extractedData.ui = {
            ...extractedData.ui,
            message: getTrivialErrorTypesMessage(extractedData?.data?.parsedType) as JSX.Element,
        };
    }
    return extractedData;
};

export type ComprehensiveErrorData = {
    data?: Partial<ReturnType<typeof extractAxiosErrorData> & ReturnType<typeof extractErrorData>>;
    additionalData?: Record<string, any>;
    ui?: {
        title?: NotificationContentOptions['title'];
        message?: NotificationPayload['message'];
        shouldRenderTechDetails?: boolean;
        footerFn?: NotificationContentOptions['footerFn'];
        additionalMessage?: ReactNode;
    };
};
