import axios, { AxiosInstance } from '@vtblife/axios';

class NewsService {
    api: AxiosInstance;
    constructor() {
        this.api = axios.create({ baseURL: '/api/news-express/api/v1' });
    }

    getNews() {
        return this.api.get('/personal-area-news');
    }
}

export const newsService = new NewsService();
