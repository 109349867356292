import React, { FC, useContext } from 'react';
import cn from 'classnames';

import './login-button.module.css';
import { SvgIcon } from '../svg-icon/svg-icon';
import RightCircleIcon from '../images/arrow-right-circle.component.svg';
import { HeaderContextValue } from '../header-context';

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    loginButton: 'loginButton',
    loginButtonTransparent: 'loginButtonTransparent',
    loginButtonIcon: 'loginButtonIcon',
    loginButtonText: 'loginButtonText',
};

interface LoginButtonProps {
    text?: string;
}

export const LoginButton: FC<LoginButtonProps> = ({ text }) => {
    const { isTransparent } = useContext(HeaderContextValue);
    return (
        <button className={cn(styles.loginButton, { [styles.loginButtonTransparent]: isTransparent })}>
            <span className={styles.loginButtonIcon}>
                <SvgIcon iconComponent={<RightCircleIcon />} />
            </span>
            <span className={styles.loginButtonText}>{text || 'Войти'}</span>
        </button>
    );
};
