import { cssTransition } from 'react-toastify';

import styles from './animations.module.css';

export const SlideWithFade = cssTransition({
    enter: styles.enter,
    exit: styles.exit,
    duration: [500, 500],
    appendPosition: false,
    collapse: false,
    collapseDuration: 0,
});
