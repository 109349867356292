// copy-past from react-use with additional check https://github.com/streamich/react-use/blob/master/src/usePermission.ts
// permissions are not supported in android webview, so we check it

import { useEffect, useState } from 'react';

export type IState = PermissionState | '';

interface IPushPermissionDescriptor extends PermissionDescriptor {
    name: 'push';
    userVisibleOnly?: boolean;
}

export type IPermissionDescriptor = PermissionDescriptor | IPushPermissionDescriptor;

export const noop = () => {};

export function on<T extends Window | Document | HTMLElement | EventTarget>(
    obj: T | null,
    ...args: Parameters<T['addEventListener']> | [string, () => void | null, ...any]
): void {
    if (obj && obj.addEventListener) {
        obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
    }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
    obj: T | null,
    ...args: Parameters<T['removeEventListener']> | [string, () => void | null, ...any]
): void {
    if (obj && obj.removeEventListener) {
        obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
    }
}

export const isBrowser = typeof window !== 'undefined';

export const isNavigator = typeof navigator !== 'undefined';

export const usePermission = (permissionDesc: IPermissionDescriptor): IState => {
    const [state, setState] = useState<IState>('');

    useEffect(() => {
        let mounted = true;
        let permissionStatus: PermissionStatus | null = null;

        const onChange = () => {
            if (!mounted) {
                return;
            }
            setState(() => permissionStatus?.state ?? '');
        };

        if (isNavigator && navigator.permissions) {
            navigator.permissions
                .query(permissionDesc)
                .then((status) => {
                    permissionStatus = status;
                    on(permissionStatus, 'change', onChange);
                    onChange();
                })
                .catch(noop);
        }

        return () => {
            permissionStatus && off(permissionStatus, 'change', onChange);
            mounted = false;
            permissionStatus = null;
        };
    }, [permissionDesc]);

    return state;
};
