import React, { useEffect, useState } from 'react';
import { Select, Input, useSelectCustomControl } from '@vtblife/uikit';
import axios from '@vtblife/axios';

import { useDebounce } from '../../../../hooks/use-debounce';
import { geoApiService, GeoSuggestion, GeoSuggestions } from '../../../../services/geo-api';
import styles from './region-suggest.module.css';

const FETCH_DELAY = 300;

interface InputControlProps {
    value: string;
    hasSuggestions: boolean;
    onChange: (value: string) => void;
    onReset?: () => void;
}

const InputControl: React.FC<InputControlProps> = ({ value, onChange, onReset, hasSuggestions }) => {
    const { toggle, opened, control } = useSelectCustomControl();
    const [isVisible, setIsVisible] = useState(false);
    const handleFocus = () => {
        setIsVisible(true);
    };
    const handleBlur = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (!isVisible && opened) {
            toggle();
        } else if (hasSuggestions && isVisible && !opened) {
            toggle();
        } else if (!hasSuggestions && opened) {
            toggle();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSuggestions, opened, isVisible]);

    return (
        <div ref={control}>
            <Input
                size="l"
                value={value}
                placeholder="Город"
                dataTest="change-region-modal-input"
                onChange={onChange}
                onReset={onReset}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </div>
    );
};

interface SuggestProps {
    value?: GeoSuggestion;
    onChange: (value?: GeoSuggestion) => void;
}

export const RegionSuggest: React.FC<SuggestProps> = ({ value, onChange }) => {
    const [suggestions, setSuggestions] = useState<GeoSuggestions>();
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, FETCH_DELAY);
    const handleInputChange = (inputValue: string) => {
        value && onChange(undefined);
        setInputValue(inputValue);
    };
    const handleInputReset = () => {
        value && onChange(undefined);
        setSuggestions(undefined);
    };

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (!debouncedInputValue) return;

        geoApiService
            .getGeoSuggest(debouncedInputValue, source.token)
            .then(({ data }) => {
                setSuggestions(data);
            })
            .catch((error) => {
                if (axios.isCancel(error)) return;
            });
        return source.cancel;
    }, [debouncedInputValue]);

    return (
        <Select>
            <InputControl
                value={value?.label || inputValue}
                onChange={handleInputChange}
                onReset={handleInputReset}
                hasSuggestions={Boolean(suggestions && suggestions.length)}
            />
            <Select.Content bounds={true}>
                <div className={styles.suggestions} data-test="change-region-modal-suggest-list">
                    {suggestions?.map((item, index) => (
                        <div
                            key={item.value}
                            className={styles.suggestion}
                            data-test={`change-region-modal-suggest-item-${index}`}
                            onClick={() => {
                                onChange(item);
                                setSuggestions(undefined);
                            }}
                        >
                            <div className={styles.suggestionTitle}>{item.label}</div>
                            {item.narrowRegion && (
                                <div className={styles.suggestionDescription}>{item.narrowRegion.name}</div>
                            )}
                        </div>
                    ))}
                </div>
            </Select.Content>
        </Select>
    );
};
