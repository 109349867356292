import { action, observable, reaction, toJS } from 'mobx';
import { EventBus } from '@vtblife/event-bus';
import { AppContextChanged } from '@vtblife/event-bus-events';
import { reportErrorToSentry } from '@vtblife/layout-components/shell/utils';

import { isBrowser } from '@vtblife/layout-components/utils/is-browser';

import { RootStore } from './root';

const SESSION_STORAGE_KEY = 'M2_UTM_DATA';

export class UTMStore {
    @observable utmUserData: Record<string, string> | undefined;

    private eventBus = EventBus.getInstance();

    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        if (isBrowser()) {
            this.getUtmData();

            reaction(
                () => this.utmUserData,
                () => this.publishAppContextChanged(),
            );
        }
    }

    toJS() {
        return toJS(this.utmUserData);
    }

    @action
    setUtm(utm: Record<string, string>) {
        this.utmUserData = utm;
    }

    get utm() {
        return toJS(this.utmUserData);
    }

    getUtmData() {
        const { historyStore } = this.rootStore;
        const { search } = historyStore.location || {};

        const utmPrefix = 'utm_';
        const parsedUtm = Array.from(new URLSearchParams(search))
            .filter(([key]) => key.startsWith(utmPrefix))
            .reduce((acc, [key, value]) => ({ ...acc, [key.slice(utmPrefix.length)]: value }), {});
        if (Object.keys(parsedUtm).length !== 0) {
            this.setUtm(parsedUtm);

            try {
                localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(parsedUtm));
            } catch (error) {
                reportErrorToSentry({ error, extra: { SESSION_STORAGE_KEY, parsedUtm } });
            }
        } else {
            let storedUtmStr = '';

            try {
                storedUtmStr = localStorage[SESSION_STORAGE_KEY];
            } catch (error) {
                reportErrorToSentry({ error, extra: { SESSION_STORAGE_KEY } });
            }

            if (!storedUtmStr) {
                return;
            }

            this.setUtm(JSON.parse(storedUtmStr));
        }
    }

    private publishAppContextChanged(): void {
        const event: AppContextChanged = {
            type: 'app:contextChanged',
            data: {
                authInfo: this.rootStore.userStore.toJS(),
                config: this.rootStore.configStore.toJS(),
                utm: this.toJS(),
                bundleTree: this.rootStore.bundleTreeStore.toJS(),
            },
            category: 'behavior',
        };
        this.eventBus.publish(event);
    }
}
