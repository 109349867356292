import React, { FC, useContext, useEffect, useState } from 'react';
import { Partners as PartnersType } from '@vtblife/layout-config/types';
import cn from 'classnames';

import { MenuItemBase } from '../menu-item-base/menu-item-base';
import './partners-button.module.css';
import { HeaderContextValue } from '../header-context';
import { sendAnalyticsEvent } from '../../analytics';

type PartnersButtonProps = {
    partners: PartnersType;
    activePartner?: string;
    onPartnerClick?: (name: string) => void;
};

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    partnersButton: 'partnersButton',
    partnersButtonLogoTransparent: 'partnersButtonLogoTransparent',
    partnersButtonLogoWhiteTransparent: 'partnersButtonLogoWhiteTransparent',
};

export const PartnersButton: FC<PartnersButtonProps> = ({ partners, activePartner, onPartnerClick }) => {
    // Инициируем partnerName из прокинутой пропсы
    const [partnerName, setPartnerName] = useState(activePartner);
    const { isTransparent, activeRegionPartner } = useContext(HeaderContextValue);

    useEffect(() => {
        if (activePartner && activePartner === partnerName) return;
        // Приоритет activePartner, так как приложение может само решать какого партнера показывать.
        // Иначе ориентируемся на expectedPartner из куки
        setPartnerName(activePartner || activeRegionPartner);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePartner, activeRegionPartner]);

    if (!partnerName || !partners[partnerName]) {
        return null;
    }

    const { logo, logoWhite, path, url, analyticsDataClick } = partners[partnerName];

    const onClickHandler = () => {
        if (analyticsDataClick) {
            sendAnalyticsEvent(analyticsDataClick);
        }
        if (onPartnerClick) {
            onPartnerClick(partnerName);
        }
    };

    return (
        <MenuItemBase
            data-test="header-partner-button"
            className={cn(styles.partnersButton)}
            path={path}
            url={url}
            onClick={onClickHandler}
        >
            <img src={logo} alt="partner logo" className={cn(isTransparent && styles.partnersButtonLogoTransparent)} />
            {isTransparent && (
                <img src={logoWhite} alt="partner logo" className={styles.partnersButtonLogoWhiteTransparent} />
            )}
        </MenuItemBase>
    );
};
