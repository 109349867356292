import React, { FC, useEffect, useState, Suspense } from 'react';
import { MicroBundle } from '@vtblife/micro-frontend-core';
import { EventBus } from '@vtblife/event-bus';
import { OpenChatEventType, OpenChatPayload, CloseChatEventType, CloseChatPayload } from '@vtblife/event-bus-events';
import { History } from 'history';
import { observer } from 'mobx-react';

import { BundleTreeStore } from '../../stores/bundle-tree';
import { ConfigStore } from '../../stores/config';
import { UserStore } from '../../stores/user';
import { ErrorBoundary } from '../error-boundary/error-boundary';
import { CHAT_APP_NAME } from '../../constants';

interface Props {
    initialState: InitialState;
    history: History;
    userStore: UserStore;
    configStore: ConfigStore;
    bundleTreeStore: BundleTreeStore;
}

export const ChatApp: FC<Props> = observer(({ initialState, history, bundleTreeStore, configStore, userStore }) => {
    const [chatId, setChatId] = useState<string | null>(null);
    useEffect(() => {
        const eventBus = (window.eventBus = EventBus.getInstance());
        const subscriptions = [
            eventBus.subscribe<OpenChatEventType, OpenChatPayload>('chat:open', ({ data }) => {
                setChatId(data.chatId);
            }),
            eventBus.subscribe<CloseChatEventType, CloseChatPayload>('chat:close', () => {
                setChatId(null);
            }),
        ];

        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, []);

    const route = bundleTreeStore.bundleTreeRoutes.find((route) => route.applicationName === CHAT_APP_NAME);
    if (!route) {
        return null;
    }

    return (
        <Suspense fallback={<></>}>
            {/* @ts-expect-error */}
            <ErrorBoundary
                extraInfo={{
                    user: userStore.toJS(),
                    config: configStore.toJS(),
                    bundleTreeRoutes: bundleTreeStore.bundleTreeRoutes,
                }}
            >
                <MicroBundle
                    name={route.applicationName}
                    path={route.assetsUrl || configStore.getApplicationPath(route.applicationName)}
                    basePath={route.path}
                    history={history}
                    isButtonApp
                    withOwnSidebar
                    authInfo={userStore.toJS()}
                    config={configStore.toJS()}
                    initialState={initialState}
                    chatId={chatId === 'chat-list' ? null : chatId}
                    initialPage={chatId && chatId !== 'chat-list' ? 'concrete-chat' : 'chat-list'}
                />
            </ErrorBoundary>
        </Suspense>
    );
});
