import React from 'react';
import cn from 'classnames';

import styles from './logo.module.css';
import { LogoImages, LogoVariant } from './images';

interface LogoProps {
    variant: LogoVariant;
    transform?: string;
}

export const Logo: React.FC<LogoProps> = ({ variant, transform }) => {
    const logo = LogoImages[variant] || LogoImages.m2;
    const { desktop: LogoDesktop, touch: LogoTouch } = logo;

    return (
        <>
            <span className={cn(styles.logo, styles.logoDesktop)} style={{ transform }}>
                <LogoDesktop />
            </span>
            <span className={cn(styles.logo, styles.logoTouch)}>
                <LogoTouch />
            </span>
        </>
    );
};
