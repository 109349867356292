import { Modal } from '@vtblife/uikit';
import React, { useCallback, useEffect, useState } from 'react';

import styles from './survey.module.css';
import { useSurveyContext } from '../../contexts';

export const Survey = () => {
    const { currentSurvey, closeCurrentSurvey } = useSurveyContext();
    const [opened, setOpened] = useState(!!currentSurvey);

    useEffect(() => {
        setOpened(true);
    }, [currentSurvey]);

    const handleCloseCurrentSurvey = useCallback(() => {
        setOpened(false);
        closeCurrentSurvey?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!currentSurvey) {
        return null;
    }

    return (
        <>
            <Modal opened={opened} size="l" onClose={handleCloseCurrentSurvey} dataTest="feedback-survey">
                <iframe className={styles.iframe} name="survey" src={currentSurvey.link} data-scroll-lock-ignore />
            </Modal>
        </>
    );
};
