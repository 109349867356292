import React, { useContext } from 'react';
import Icon from '@vtblife/uikit-icons';
import cn from 'classnames';

import { MenuItemBase } from '../../menu-item-base/menu-item-base';
import styles from './user-info-menu-button.module.css';
import { HeaderContextValue } from '../../header-context';

interface UserInfoMenuButtonProps {
    isOpened?: boolean;
    path?: string;
    url?: string;
    onClick?: () => void;
}

// css-loader doesn't export global, we use globals for critical extraction
const globalStyles = {
    userInfoMenuButtonIcon: 'userInfoMenuButtonIcon',
    userInfoMenuButtonIconTransparent: 'userInfoMenuButtonIconTransparent',
};

export const UserInfoMenuButton: React.FC<UserInfoMenuButtonProps> = ({ path, url, onClick }) => {
    const { isTransparent } = useContext(HeaderContextValue);
    return (
        <MenuItemBase
            onClick={onClick}
            path={path}
            url={url}
            isCloseMenuDisabled={true}
            className={cn(styles.userInfoMenuButton, {
                [styles.userInfoMenuButtonTransparent]: isTransparent,
            })}
        >
            <span className={styles.userInfoMenuButtonContent}>
                <span
                    className={cn(globalStyles.userInfoMenuButtonIcon, {
                        [globalStyles.userInfoMenuButtonIconTransparent]: isTransparent,
                    })}
                >
                    <Icon name="person-circle" size="m" />
                </span>
                <span className={styles.userInfoMenuButtonText}>
                    <span className={styles.userInfoMenuButtonTextInner}>Личный кабинет</span>
                </span>
            </span>
        </MenuItemBase>
    );
};
