import { RouterStore } from 'mobx-react-router';

import { UserStore } from './user';
import { ConfigStore } from './config';
import { HeaderStore } from './header';
import { BundleTreeStore } from './bundle-tree';
import { FooterStore } from './footer';
import { LayoutStore } from './layout';
import { SidebarStore } from './sidebar';
import { UserRegionStore } from './user-region';
import { ModalStore } from './modal-store';
import { UTMStore } from './utm';
import { UsedeskStore } from './usedesk-store';

export class RootStore {
    historyStore: RouterStore;
    userStore: UserStore;
    configStore: ConfigStore;
    headerStore: HeaderStore;
    bundleTreeStore: BundleTreeStore;
    footerStore: FooterStore;
    layoutStore: LayoutStore;
    sidebarStore: SidebarStore;
    userRegionStore: UserRegionStore;
    utmStore: UTMStore;
    modalStore: ModalStore;
    usedeskStore: UsedeskStore;

    constructor(historyStore: RouterStore, initialState?: InitialState) {
        this.historyStore = historyStore;
        this.utmStore = new UTMStore(this);
        this.headerStore = new HeaderStore(this, initialState?.headerStore);
        this.bundleTreeStore = new BundleTreeStore(this, initialState?.bundleTreeStore);
        this.footerStore = new FooterStore(this, initialState?.footerStore);
        this.userStore = new UserStore(this, initialState?.userStore);
        this.layoutStore = new LayoutStore(this, initialState?.layoutStore);
        this.sidebarStore = new SidebarStore(this, initialState?.sidebarStore);
        this.configStore = new ConfigStore(this, initialState?.configStore);
        this.userRegionStore = new UserRegionStore(this);
        this.modalStore = new ModalStore();
        this.usedeskStore == new UsedeskStore(this);
    }
}
