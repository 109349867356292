import React, { useContext } from 'react';
import { Tooltip, useTooltip } from '@vtblife/uikit';
import cn from 'classnames';

import './icon-link.module.css';

import { MenuItemBase, MenuItemBaseProps } from '../menu-item-base/menu-item-base';
import { SvgIcon } from '../svg-icon/svg-icon';
import { HeaderContextValue } from '../header-context';

interface IconLinkProps extends MenuItemBaseProps {
    title: string;
    iconComponent: React.ReactNode;
    color?: string;
}

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    iconLinkMobile: 'iconLinkMobile',
    iconLinkDesktop: 'iconLinkDesktop',
    iconLinkDesktopTransparent: 'iconLinkDesktopTransparent',
    iconLink: 'iconLink',
};

export const IconLink = ({
    url,
    title,
    iconComponent,
    dataTest,
    onClick,
    isCloseMenuDisabled,
    color,
}: IconLinkProps) => {
    const { to, registerControl } = useTooltip();
    const { isTransparent } = useContext(HeaderContextValue);
    if (!url && !onClick) return null;

    return (
        <MenuItemBase url={url} dataTest={dataTest} isCloseMenuDisabled={isCloseMenuDisabled} onClick={onClick}>
            <span className={styles.iconLinkMobile}>
                <span className={styles.iconLink}>
                    <SvgIcon iconComponent={iconComponent} color={color} />
                </span>
            </span>
            <span
                className={cn(styles.iconLinkDesktop, {
                    [styles.iconLinkDesktopTransparent]: isTransparent,
                })}
                ref={registerControl}
            >
                <span className={styles.iconLink}>
                    <SvgIcon iconComponent={iconComponent} color={color} />
                    <Tooltip to={to} direction="down" placement="body">
                        {title}
                    </Tooltip>
                </span>
            </span>
        </MenuItemBase>
    );
};
