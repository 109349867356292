import React, { useContext } from 'react';
import Icon from '@vtblife/uikit-icons';
import { IconMediumSize } from '@vtblife/uikit-icons/dist/types';
import cn from 'classnames';

import { MenuItemBase } from '../../menu-item-base/menu-item-base';
import styles from './dropdown-menu-button.module.css';
import { HeaderContextValue } from '../../header-context';
import { MenuItemNotification } from '../../menu-item-notification/menu-item-notification';

export interface DropdownMenuButtonProps {
    label?: string;
    icon?: string;
    className?: string;
    isMainMenu?: boolean;
    isOpen?: boolean;
    onClick?: () => void;
    showNotification?: boolean;
}

export const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({
    label,
    icon,
    className,
    isMainMenu,
    isOpen,
    onClick,
    showNotification,
}) => {
    const { isTransparent, isTablet } = useContext(HeaderContextValue);

    return (
        <MenuItemBase
            className={cn(styles.dropdownMenuButton, className, {
                [styles.dropdownMenuButtonTransparent]: isTransparent,
                [styles.dropdownMenuButtonMain]: isMainMenu,
            })}
            onClick={onClick}
            isCloseMenuDisabled={true}
        >
            <div className={styles.dropdownMenuButtonContent}>
                {!isMainMenu && icon && (
                    <span className={styles.dropdownMenuButtonIcon}>
                        <span className={styles.dropdownMenuButtonIconInner}>
                            <Icon name={icon as IconMediumSize} size="m" />
                        </span>
                    </span>
                )}
                <span className={styles.dropdownMenuButtonText}>{label}</span>
                {isTablet && (
                    <span className={styles.toggler}>
                        <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} size="s" />
                    </span>
                )}
                {showNotification && <MenuItemNotification />}
            </div>
        </MenuItemBase>
    );
};
