import { INTERNAL_DOMAINS } from '@vtblife/layout-components/constants/domains';

import { isValidPath } from './is-valid-path';

export const isInternalDomain = (domain?: string) => {
    if (!domain) {
        return false;
    }
    const rootDomain = domain.split('.').splice(-2).join('.');
    return INTERNAL_DOMAINS.includes(rootDomain);
};

export const normalizeRetpath = (retpath?: string) => {
    if (!isValidPath(retpath)) return '';
    if (!retpath || retpath.startsWith('/')) return retpath || '';
    return retpath.replace(/(^https?:\/\/)?(.*?)\/(.*)?/, (_match, _protocol, domain, path) => {
        if (!isInternalDomain(domain)) return '';
        return `/${path}`;
    });
};
