import React from 'react';
import { inject, observer } from 'mobx-react';
import { Header as LayoutComponentsHeader } from '@vtblife/layout-components/header';
import { LogoVariant } from '@vtblife/layout-components/header/logo/images';

import { HeaderStore } from '../../stores/header';
import { ConfigStore } from '../../stores/config';
import { UserStore } from '../../stores/user';
import { LayoutStore } from '../../stores/layout';
import { SidebarStore } from '../../stores/sidebar';
import { useHandleMenuItemClick } from '../../hooks/handle-menu-item-click';

interface HeaderProps {
    configStore: ConfigStore;
    userStore: UserStore;
    layoutStore: LayoutStore;
    headerStore: HeaderStore;
    sidebarStore: SidebarStore;
    className?: string;
    logoVariant?: LogoVariant;
    isFullWidth?: boolean;
    isTransparent?: boolean;
    pathname: string;
}

const HeaderComponent: React.FC<HeaderProps> = (props) => {
    const {
        headerStore,
        configStore,
        userStore,
        sidebarStore,
        className,
        pathname,
        logoVariant,
        isFullWidth,
        isTransparent,
    } = props;
    const { currentPath, headerProps = {}, hasSidebar }: LayoutStore = props.layoutStore;
    const cdnUrl = (configStore as ConfigStore).cdnUrl;
    const currentRoleType = (userStore as UserStore).user?.currentRoleType;
    const handleMenuItemClick = useHandleMenuItemClick();
    const sidebarConfig = sidebarStore.sidebarConfig((userStore as UserStore).user);

    return (
        <LayoutComponentsHeader
            sticky={headerProps.sticky}
            isBordered={headerProps.bordered}
            logoVariant={hasSidebar ? sidebarConfig.logo.variant : logoVariant || headerProps.logoVariant}
            isFullWidth={isFullWidth || headerProps.isFullWidth}
            isTransparent={isTransparent || headerProps.isTransparent}
            hideElementsDependOnAuthState={headerProps.hideElementsDependOnAuthState}
            isColored
            cdnUrl={cdnUrl}
            className={className}
            partners={headerStore.header.partners}
            personalButtons={headerStore.header.personalButtons}
            pathname={pathname}
            logoHref={headerStore.header.logoHref}
            loginPaths={headerStore.header.loginPaths}
            items={headerStore.header.items}
            authorizedItems={sidebarConfig}
            roleType={currentRoleType}
            onMenuItemClick={handleMenuItemClick}
            logoutPath={`/logout?retpath=${encodeURIComponent(currentPath)}`}
            loginText="Войти"
            mobileLoginText="Войти или зарегистрироваться"
        />
    );
};

export const Header = inject(
    'headerStore',
    'sidebarStore',
    'layoutStore',
    'configStore',
    'userStore',
)(observer(HeaderComponent)) as React.FC<
    Omit<HeaderProps, 'headerStore' | 'sidebarStore' | 'layoutStore' | 'configStore' | 'userStore'>
>;
