import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { Config } from '@vtblife/event-bus-events';
import { Layout } from '@vtblife/layout-components/layout';
import { Shell as ShellProvider } from '@vtblife/layout-components/shell/shell';
import { ToasterContainer } from '@vtblife/layout-components/shell/components/notification-toaster/toaster-adapter/toaster-adapter';
import { LogoVariant } from '@vtblife/layout-components/header/logo/images';
// TODO: refresh eslint and prettier config
// eslint-disable-next-line prettier/prettier
import type { History } from 'history';
import Cookies from 'js-cookie';
import { isAutotestUserAgent } from '@vtblife/layout-components/utils/is-autotest-user-agent';

import * as rootStyles from '../../adapters/root-adapter/styles';
import { UserStore } from '../../adapters/root-adapter/user-store';
import { ConfigStore } from '../../adapters/root-adapter/config-store';
import { BundleTreeStore } from '../../adapters/root-adapter/bundle-tree-store';
import { Footer } from '../../adapters/root-adapter/footer';
import { Header } from '../../adapters/root-adapter/header';
import { AuthApp } from '../../adapters/root-adapter/auth-app';
import { ChatApp } from '../../adapters/root-adapter/chat-app';
import { initialState as mainInitialState } from '../../utils/initial-state.autogenerated';
import { normalizeRetpath } from '../../utils/normalize-retpath';
import { ModalStore } from '../../adapters/root-adapter/modal-store';

interface Props {
    initialState?: InitialState;
    history: History;
    /** @description переопределяет логотип в хедере */
    logoVariant?: LogoVariant;
    isFullWidth?: boolean;
    isTransparent?: boolean;
    hasHeader: boolean;
    hasFooter: boolean;
    pathname: string;
    modalStore: ModalStore;
}

interface InjectedProps {
    userStore: UserStore;
    configStore: ConfigStore;
    bundleTreeStore: BundleTreeStore;
    historyStore: RouterStore;
}

interface State {
    displayConsent: boolean;
    shouldShowChatWidget: boolean;
}

interface ShellContextType {
    initialState: InitialState;
    config: Config;
    authInfo: {
        user: any;
        isAuthenticated: boolean;
    };
}

export const ShellContext = React.createContext<ShellContextType>(null as unknown as ShellContextType);

export class ShellBase extends React.Component<Props & InjectedProps, State> {
    
    constructor(props: Props & InjectedProps) {
        super(props);

        // Для shouldShowChatWidget дефолтным значением должно оставаться false.
        // иначе загрузка скриптов usedesk начнется до того, как мы его переопределим. И виджет соответственно отобразится
        this.state = { shouldShowChatWidget: false, displayConsent: false };
        
    }
    async componentDidMount(): Promise<void> {
        const { configStore } = this.props;
        configStore.fetchConfig();

        let userAgent = '';

        if (typeof navigator !== 'undefined') {
            userAgent = window.navigator.userAgent;
        }

        const IS_WEBVIEW_CLIENT = Cookies.get('is_webview_client') === 'true';

        this.setState({
            shouldShowChatWidget: !IS_WEBVIEW_CLIENT && !isAutotestUserAgent(userAgent),
        });
    }

    render() {
        const {
            configStore,
            userStore,
            initialState = mainInitialState,
            history,
            children,
            bundleTreeStore,
            hasHeader,
            hasFooter,
            pathname,
            modalStore,
            logoVariant,
            isFullWidth,
            isTransparent,
        } = this.props;

        if (userStore.onAuthFinishedRedirectPath && userStore.isAuthorized) {
            const redirectUrl = decodeURIComponent(
                normalizeRetpath(userStore.onAuthFinishedRedirectPath) || '/personal-area',
            );
            window.location.href = redirectUrl;
            return null;
        }

        return (
            <ShellProvider
                user={userStore.user}
                shouldDisableRegionModal={false}
                shouldAskCookieAgreement={true}
                shouldShowChatWidget={this.state.shouldShowChatWidget}
                disableCheckAuthState={true}
                config={configStore.toJS()}
                modalStore={modalStore}
            >
                <AuthApp
                    history={history}
                    initialState={initialState as InitialState}
                    userStore={userStore}
                    configStore={configStore}
                    bundleTreeStore={bundleTreeStore}
                />
                <ChatApp
                    history={history}
                    initialState={initialState as InitialState}
                    userStore={userStore}
                    configStore={configStore}
                    bundleTreeStore={bundleTreeStore}
                />
                <Layout
                    header={
                        hasHeader ? (
                            <Header
                                pathname={pathname}
                                logoVariant={logoVariant}
                                isFullWidth={isFullWidth}
                                isTransparent={isTransparent}
                            />
                        ) : null
                    }
                    footer={
                        hasFooter ? <Footer isLandingWidth={true} /> : null
                    }
                >
                    <div className={rootStyles.styles.wrapper}>
                        <ShellContext.Provider
                            value={{
                                config: configStore.toJS(),
                                authInfo: userStore.toJS(),
                                initialState: initialState as InitialState,
                            }}
                        >
                                {children}
                        </ShellContext.Provider>
                    </div>
                </Layout>
                <ToasterContainer />
            </ShellProvider>
        );
    }
}

export const Shell = inject(
    'userStore',
    'configStore',
    'bundleTreeStore',
    'historyStore',
)(observer(ShellBase)) as unknown as React.FC<Props>;
