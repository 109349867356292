import m2Logo from './m2-logo.component.svg';
import m2ProLogo from './m2-pro-logo.component.svg';
import m2ProLogoCollapsed from './m2-pro-logo-collapsed.component.svg';
import avitoLogo from './avito-logo.component.svg';
import sravniruLogo from './sravniru_logo.component.svg';
import groupLogo from './group-logo.component.svg';
import aeonLogo from '../../../images/aeon-logo.component.svg';
import samoletLogo from './samolet.component.svg';
import { LogoVariant } from '../../../header/logo/images';

type Image = {
    position?: 'left' | 'right' | 'single';
    image: string;
    collapsedImage?: string;
};

export const logoImages: Record<LogoVariant, Image> = {
    m2: {
        image: m2Logo,
    },
    'm2-light': {
        image: m2Logo,
    },
    'm2-pro': {
        image: m2ProLogo,
        collapsedImage: m2ProLogoCollapsed,
        position: 'single',
    },
    avito: {
        position: 'left',
        image: avitoLogo,
    },
    sravniru: {
        position: 'left',
        image: sravniruLogo,
    },
    group: {
        position: 'right',
        image: groupLogo,
    },
    vtb: {
        image: m2Logo,
    },
    aeon: {
        image: aeonLogo,
        position: 'single',
    },
    samolet: {
        image: samoletLogo,
        position: 'single',
    },
};
