import React from 'react';

import styles from './layout.module.css';
import { DefaultLayout } from './layouts/default';
import { StyledComponent } from '../helpers';

export enum LayoutType {
    Default = 'default',
    Sidebar = 'sidebar',
    SidebarAndFooter = 'sidebar-and-footer',
}

export interface Props extends StyledComponent {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    sidebar?: React.ReactNode;
    isAnonymous?: boolean;
}

const switchLayout = (props: Props) => {
    return <DefaultLayout {...props} />;
};

export const Layout: React.FC<Props> = ({ ...otherProps }) => {
    const props = {
        ...otherProps,
        className: styles.root,
    };

    return switchLayout(props);
};
