import { useEffect, useState } from 'react';
import { getWalletBalance } from '@vtblife/billing-api/axios-gen/billing_service';
import { AxiosError } from '@vtblife/axios';

import { reportErrorToSentry, useAuthorizeContext } from '../shell';

export const useDisplayWallet = () => {
    const { user } = useAuthorizeContext();
    const [walletBalance, setWalletBalance] = useState<number | null>(null);

    const { currentRoleType, companyId, userId, currentRole, permissions } = user || {};
    const hasWallet = permissions?.includes('/billing/wallet/loyalty:read');
    const isRealtorEmpowered = currentRoleType === 'REALTOR' && currentRole === '37';
    const isProf = currentRoleType === 'PROFESSIONAL';
    const shouldDisplayWallet = (isRealtorEmpowered || isProf) && hasWallet;
    const ownerId = isProf ? userId : companyId;
    const zeroBalanceLink = isRealtorEmpowered ? `/billing/${ownerId}` : '/billing';
    const zeroBalanceText = isRealtorEmpowered ? 'Кошельки' : 'Перейти в кошелёк';

    // Если amount === null, то это означает, что не пришел баланс кошелька
    // Если amount === 0, то это значит, что есть кошелёк с нулевым балансом

    const walletLink = walletBalance === null ? zeroBalanceLink : `/billing/${ownerId}/loyalty/details`;
    const walletText = walletBalance === null ? zeroBalanceText : walletBalance.toLocaleString('ru') + ' ₽';

    useEffect(() => {
        if (shouldDisplayWallet) {
            getWalletBalance({ params: { product: 'LOYALTY', companyId: ownerId! } })
                .then((response) => {
                    if ('balance' in response) {
                        const numericBalance = Number(response.balance || 0) / 100;
                        setWalletBalance(numericBalance);
                    }
                })
                .catch((error: AxiosError) => {
                    // @TODO: remove 404 check after billing backend release (Если приходит 404 ошибка, то это значит, что у юзера всего лишь нет кошелька. Её нужно игнорить)
                    if (!(error.isAxiosError && error.response?.status === 404)) {
                        reportErrorToSentry({ error });
                    }
                });
        }
    }, [shouldDisplayWallet, companyId, ownerId]);

    return { shouldDisplayWallet, walletBalance, walletLink, walletText };
};
