import React from 'react';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';

import { createHistory } from '../compat/history';
import { initialState, regionsConfigs } from '../utils/initial-state.autogenerated';
import { RootStore } from '../adapters/root-adapter/root-store';
import { bootstrapBrowserEnvironment } from '../adapters/root-adapter/bootstrap-browser-environment';
import { synchronizeHistory } from '../adapters/root-adapter/synchronize-history';
import { withTrailingSlash } from '../utils/with-trailing-slash';

const isSSR = typeof window === 'undefined';
const gatsbyCompatHistory = createHistory();

const { store: historyStore } = synchronizeHistory(gatsbyCompatHistory);
const rootStore = new RootStore(historyStore, initialState as any);

const REGION_PATH_RE = new RegExp(`^/(${Object.keys(regionsConfigs).join('|')})/`);

const originalHeaderConfig = rootStore.headerStore.header;
const originalFooterConfig = {
    top: rootStore.footerStore.top,
    socialLinks: rootStore.footerStore.socialLinks,
    middleLinks: rootStore.footerStore.middleLinks,
    downloadAppQrCode: rootStore.footerStore.downloadAppQrCode,
};

interface Props {
    element: JSX.Element;
    pathname: string;
}

export function wrapRootElement(props: Props) {
    return <WrapProvider {...props} />;
}
// can't use hooks here for some reason
export const WrapProvider = ({ element, pathname }: Props) => {
    if (!isSSR) {
        bootstrapBrowserEnvironment(undefined);
        const user = window.__INITIAL_STATE__?.userStore?.user;
        const config = window.__INITIAL_STATE__?.configStore;
        rootStore.userStore.setInitialState({ user });
        rootStore.configStore.setInitialState(config);
    } else {
        historyStore.location.pathname = pathname;
    }

    const regionRegexResult = pathname ? withTrailingSlash(pathname).match(REGION_PATH_RE) : null;
    if (regionRegexResult) {
        rootStore.headerStore.setHeader(regionsConfigs[regionRegexResult[1]].header);
        rootStore.footerStore.setFooter(regionsConfigs[regionRegexResult[1]].footer);
    } else {
        rootStore.headerStore.setHeader(originalHeaderConfig);
        rootStore.footerStore.setFooter(originalFooterConfig);
    }

    return (
        <Provider
            rootStore={rootStore}
            historyStore={historyStore}
            configStore={rootStore.configStore}
            userStore={rootStore.userStore}
            headerStore={rootStore.headerStore}
            footerStore={rootStore.footerStore}
            bundleTreeStore={rootStore.bundleTreeStore}
            layoutStore={rootStore.layoutStore}
            sidebarStore={rootStore.sidebarStore}
        >
            <Router history={gatsbyCompatHistory}>{element}</Router>
        </Provider>
    );
};
