import React, { FC } from 'react';

import styles from './burger-button.module.css';
import MenuIcon from '../images/menu.component.svg';
import { SvgIcon } from '../svg-icon/svg-icon';

interface BurgerButtonProps {
    onClick: () => void;
    dataTest?: string;
}

export const BurgerButton: FC<BurgerButtonProps> = ({ onClick, dataTest = 'burger-button' }) => (
    <button className={styles.burgerButton} onClick={onClick} data-test={dataTest}>
        <SvgIcon iconComponent={<MenuIcon />} />
    </button>
);
