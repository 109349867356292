import { useCallback, useEffect, useRef } from 'react';

export function useMountedState(): () => boolean {
    const mountedRef = useRef<boolean>(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const get = useCallback(() => mountedRef.current, [mountedRef.current]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return get;
}
