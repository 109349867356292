import React, { useContext } from 'react';
import { HeaderTabItem } from '@vtblife/layout-config/types';
import Icon from '@vtblife/uikit-icons';
import { IconMediumSize } from '@vtblife/uikit-icons/dist/types';
import cn from 'classnames';
import { UpdatingServices } from '@vtblife/layout-config/types';

import './menu-item.module.css';
import { MenuItemBase } from '../menu-item-base/menu-item-base';
import { comparePathname } from '../utils';
import { HeaderContextValue } from '../header-context';
import { MenuItemNotification } from '../menu-item-notification/menu-item-notification';
import { useServiceUpdatesContext } from '../../shell/contexts';

export interface MenuLinkProps {
    pathname?: string;
    item: HeaderTabItem & {
        freshCount?: number;
    };
    isMainMenu?: boolean;
    onClick?: () => void;
}

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    menuItem: 'menuItem',
    menuItemActive: 'menuItemActive',
    menuItemTransparent: 'menuItemTransparent',
    menuItemIcon: 'menuItemIcon',
    menuItemIconInner: 'menuItemIconInner',
    menuItemText: 'menuItemText',
    menuItemNotification: 'menuItemNotification',
};

export const MenuItem: React.FC<MenuLinkProps> = ({ item, pathname, isMainMenu, onClick }) => {
    const { id, path, url, icon, displayName, target, showNotification, isSidebarEl } = item;
    const freshCount = useServiceUpdatesContext(id as UpdatingServices);
    const shouldShowNotification = showNotification || Boolean(freshCount);
    const { isTransparent } = useContext(HeaderContextValue);
    const isActive = comparePathname(pathname, item);
    return (
        <MenuItemBase
            className={cn(styles.menuItem, {
                [styles.menuItemActive]: isActive,
                [styles.menuItemTransparent]: isTransparent,
            })}
            path={path}
            url={url}
            onClick={onClick}
            target={target}
            isActive={isActive}
            id={id}
            isSidebarEl={isSidebarEl}
        >
            {!isMainMenu && icon && (
                <span className={styles.menuItemIcon}>
                    <span className={styles.menuItemIconInner}>
                        <Icon name={icon as IconMediumSize} size="m" />
                    </span>
                </span>
            )}
            <span className={styles.menuItemText}>{displayName}</span>
            {shouldShowNotification && <MenuItemNotification />}
        </MenuItemBase>
    );
};
