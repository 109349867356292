import React, { useContext } from 'react';
import cn from 'classnames';

import './info-menu.module.css';

import { UserInfo } from '../user-info/user-info';
import { OfferPlacementLink } from '../offer-placement-link/offer-placement-link';
import { PersonalButtons } from '../personal-buttons/personal-buttons';
import { HeaderContextValue } from '../header-context';
import { getOfferPlacementButtonUrl } from '../utils';
import { useAuthorizeContext } from '../../shell/contexts';

// css-loader doesn't export global, we use globals for critical extraction
const styles = {
    infoMenu: 'infoMenu',
    infoMenuIconControls: 'infoMenuIconControls',
    infoMenuIconControlsDesktop: 'infoMenuIconControlsDesktop',
    infoMenuUserControls: 'infoMenuUserControls',
};

export const InfoMenu = () => {
    const { isAuthorized } = useAuthorizeContext();
    const { isPartnerLogoVariant, isPersonalButtonsVisible, personalButtons } = useContext(HeaderContextValue);
    return (
        <div className={styles.infoMenu}>
            {isPersonalButtonsVisible && (
                <div
                    className={cn(styles.infoMenuIconControls, {
                        [styles.infoMenuIconControlsDesktop]: isPartnerLogoVariant,
                    })}
                >
                    <PersonalButtons />
                    <OfferPlacementLink
                        url={getOfferPlacementButtonUrl(isAuthorized, personalButtons?.offerPlacement)}
                    />
                </div>
            )}
            <div className={styles.infoMenuUserControls}>
                <UserInfo />
            </div>
        </div>
    );
};
