import { SidebarItem } from '@vtblife/layout-config/types';

import { UpdatesData, UpdatingServices } from '@vtblife/layout-config/types';

const mapUpdatesToCounter = (updates: UpdatesData): Record<string, number> => {
    if (!updates) return {};
    const counters: Record<string, number> = {};
    for (const service in updates) {
        const data = updates[service as UpdatingServices];
        if (!data || typeof data === 'number') {
            counters[service] = data ?? 0;
            continue;
        }
        counters[service] = 0;
    }

    return counters;
};

export const mapUpdatesToSidebar = (items: SidebarItem[], updates?: UpdatesData) =>
    updates
        ? items.map((item) => {
              let dropdownTotalFreshCount = 0;
              const counters = mapUpdatesToCounter(updates);
              return {
                  ...item,
                  dropdownMenu: item.dropdownMenu?.map((subItem) => {
                      dropdownTotalFreshCount += counters[subItem.id] || 0;
                      return {
                          ...subItem,
                          freshCount: counters[subItem.id],
                      };
                  }),
                  freshCount: counters[item.id] || dropdownTotalFreshCount,
              };
          })
        : items;
