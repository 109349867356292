import axios, { AxiosInstance, AxiosRequestConfig } from '@vtblife/axios';

export interface Transport {
    get<T>(url: string, params?: any): Promise<T>;
    post<T>(url: string, params: any, config?: AxiosRequestConfig): Promise<T>;
}

export interface HttpTransportOptions {
    baseURL: string;
}

export class HttpTransport implements Transport {
    instance: AxiosInstance;

    constructor(options: HttpTransportOptions) {
        this.instance = axios.create({
            baseURL: options.baseURL,
        });
    }

    async get<T>(methodName: string, params: any = {}) {
        const response = await this.instance.get<T>(methodName, { params });
        return response?.data;
    }

    async post<T>(methodName: string, params: any = {}, config?: AxiosRequestConfig) {
        const response = await this.instance.post<T>(methodName, params, config);
        return response?.data;
    }
}
