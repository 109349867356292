export const LAST_AUTH_LOCAL_STORAGE_KEY = 'authState-v3';

export interface LastAuthStatus {
    isAuthorized: boolean;
    currentRoleType?: string;
    username?: string | null;
    userId?: string | null;
}

export const getLastAuth = (): LastAuthStatus | null => {
    try {
        const value = localStorage.getItem(LAST_AUTH_LOCAL_STORAGE_KEY);
        return value ? JSON.parse(value) : null;
    } catch (err) {
        return null;
    }
};

export const setLastAuth = (status: LastAuthStatus) => {
    try {
        localStorage.setItem(LAST_AUTH_LOCAL_STORAGE_KEY, JSON.stringify(status));
    } catch (err) {
        // do nothing
    }
};
