import React from 'react';
import { Button } from '@vtblife/uikit';
import { renderToStaticMarkup } from 'react-dom/server';

import { CALL_CENTER_EMAIL } from '../../../../../constants';
import { ComprehensiveErrorData, copyToClipboard } from '../../../../utils';

export const ReportErrorControl: React.FC<
    ComprehensiveErrorData & {
        onClose?: VoidFunction;
        sentryEventId?: string;
    }
> = (props) => {
    const { data = {}, ui = {}, additionalData = {}, sentryEventId } = props;

    const { traceId, varitiCcr, reasonMessage, date, requestUri, httpStatus, grpcStatus, location, userAgent } = data;
    const { deviceIp, deviceIpRequestState } = additionalData;
    const { title, message, additionalMessage } = ui;

    const content =
        '\uFEFF' + // for Windows encoding
        'Мы скопировали для вас полную информацию об ошибке, чтобы наш сотрудник поддержки смог детально разобраться в проблеме:\n' +
        '----\n' +
        `Заголовок: ${title}\n` +
        `Сообщение: ${renderToStaticMarkup(message as React.ReactElement)}\n` +
        `Дата: ${date?.toISOString()}\n` +
        `reason: ${reasonMessage}\n` +
        `x-trace-id: ${traceId || 'не определено'}\n` +
        `sentryEventId: ${sentryEventId || 'не определено'}\n` +
        `requestUri: ${requestUri || 'не определено'}\n` +
        `https-status: ${httpStatus || 'не определено'}\n` +
        `grpc-status: ${grpcStatus || 'не определено'}\n` +
        `x-variti-ccr: ${varitiCcr || 'не определено'}\n` +
        `device IP: ${deviceIp || deviceIpRequestState}\n` +
        `location: ${location || 'не определено'}\n` +
        `userAgent: ${userAgent || 'не определено'}\n` +
        `additionalMessage: ${renderToStaticMarkup(additionalMessage as React.ReactElement)}\n` +
        '----';

    const link = `mailto:${CALL_CENTER_EMAIL}?Content-Type=text/html&charset=utf-8&body=${encodeURIComponent(content)}`;

    return (
        <a href={link}>
            <Button variant="primary-alt-bg" size="s" block onClick={() => copyToClipboard(content, props.onClose)}>
                Сообщить о проблеме
            </Button>
        </a>
    );
};
