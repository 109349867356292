import { EventBus } from '@vtblife/event-bus';
import { ShowReportModalEventType, ShowReportModalPayload } from '@vtblife/event-bus-events';
import { useState, useEffect } from 'react';

type ReportModal = Partial<ShowReportModalPayload> & { isOpened: boolean };

export const useReportModal = () => {
    const [reportModal, setReportModal] = useState<ReportModal>({ isOpened: false });
    useEffect(() => {
        const sub = EventBus?.getInstance().subscribe<ShowReportModalEventType, ShowReportModalPayload>(
            'reportModal:show',
            (event) => {
                setReportModal({ ...event.data, isOpened: true });
            },
        );
        return () => {
            sub.unsubscribe();
        };
    }, []);

    return reportModal;
};
