import axios, { AxiosInstance } from '@vtblife/axios';

export class CookieApiServiceConstructor {
    transport: AxiosInstance;

    constructor(name = 'root') {
        this.transport = axios.create({
            baseURL: `/api/${name}-bff/`,
        });
    }

    async setCookieConsent(): Promise<any> {
        return this.transport.post('/api/set-cookie-consent', {});
    }

    async setCookieAgreement(userId: string): Promise<any> {
        return this.transport.post('/api/set-cookie-agreement', { userId });
    }

    async setCookieHeaderPartner(partner: string): Promise<any> {
        return this.transport.post('/api/set-cookie-header-partner', { partner });
    }
}

export const cookieApiService = new CookieApiServiceConstructor();
