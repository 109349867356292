import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useDebounce = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const handler = debounce(setDebouncedValue, delay);

    useEffect(() => {
        handler(value);
        return handler.cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, delay]);

    return debouncedValue;
};
