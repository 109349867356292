import React, { FC } from 'react';

import { FooterPhoneType } from '../types/footer.interface';
import { clearPhone } from '../helpers';

type FooterPhoneProps = {
    phone: FooterPhoneType;
    className?: string;
};

export const FooterPhone: FC<FooterPhoneProps> = ({ phone, className }) => {
    const { phoneNumber, analytics } = phone;
    const cleanPhoneNumber = clearPhone(phoneNumber);

    const clickHandler = () => {
        analytics && window.dataLayer.push({ event: analytics });
    };

    return (
        <a href={`tel:${cleanPhoneNumber}`} onClick={clickHandler} className={className}>
            {phoneNumber}
        </a>
    );
};
