import React, { useEffect } from 'react';
import { EventBus } from '@vtblife/event-bus';
import { UserRegionModalToggleEventType } from '@vtblife/event-bus-events';

import { DetectRegionModal } from '../detect-region-modal/detect-region-modal';
import { ChangeRegionModal } from '../change-region-modal/change-region-modal';
import { preventBodyScrollOverlayOpened } from '../../../utils/prevent-body-scroll-overlay-opened';
import { GeoSuggestion } from '../../../services/geo-api';
import {
    GEO_CONFIRM_CHANGE,
    GEO_CONFIRM_CROSS,
    GEO_CONFIRM_NO,
    GEO_CONFIRM_YES,
    sendAnalyticsEvent,
} from '../../../analytics';
import { useUserRegionContext } from '../../contexts';

export const RegionModals = () => {
    const {
        userRegionName,
        userRegionSelectedId,
        userRegionDetectedId,
        isDetectRegionModalVisible,
        isSelectRegionModalVisible,
        setUserRegion,
        hideDetectRegionModal,
        showSelectRegionModal,
        hideSelectRegionModal,
    } = useUserRegionContext();

    useEffect(() => {
        preventBodyScrollOverlayOpened(Boolean(isDetectRegionModalVisible || isSelectRegionModalVisible));
    }, [isDetectRegionModalVisible, isSelectRegionModalVisible]);

    const handleDetectRegionModalAccept = () => {
        setUserRegion?.({ userRegionId: userRegionDetectedId });
        hideDetectRegionModal?.();
        sendAnalyticsEvent(GEO_CONFIRM_YES);
    };

    const handleDetectRegionModalDecline = () => {
        hideDetectRegionModal?.();
        showSelectRegionModal?.();
        sendAnalyticsEvent(GEO_CONFIRM_NO);
    };

    const handleDetectRegionModalClose = () => {
        hideDetectRegionModal?.();
        sendAnalyticsEvent(GEO_CONFIRM_CROSS);
    };

    const handleSelectRegionModalClose = () => {
        hideSelectRegionModal?.();
    };

    const handleRegionSelect = (region: GeoSuggestion) => {
        const { label, value, narrowRegion } = region;
        if (value !== userRegionSelectedId) {
            setUserRegion?.({ userRegionId: value, userRegionIdNarrow: narrowRegion?.id, userRegionName: label });
        }
        hideSelectRegionModal?.();
        hideDetectRegionModal?.();
        sendAnalyticsEvent(GEO_CONFIRM_CHANGE);
    };

    useEffect(() => {
        const sub = EventBus.getInstance().subscribe<UserRegionModalToggleEventType, undefined>(
            'userRegion:modal:toggle',
            () => (isSelectRegionModalVisible ? hideSelectRegionModal?.() : showSelectRegionModal?.()),
        );
        return () => {
            sub.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectRegionModalVisible]);

    return (
        <>
            {isSelectRegionModalVisible && (
                <ChangeRegionModal
                    mobile
                    overflow={false}
                    opened={true}
                    onClose={handleSelectRegionModalClose}
                    onRegionSelect={handleRegionSelect}
                    selectedRegionId={userRegionSelectedId}
                />
            )}
            {isDetectRegionModalVisible && userRegionName && (
                <DetectRegionModal
                    regionName={userRegionName}
                    opened={true}
                    onClose={handleDetectRegionModalClose}
                    onAccept={handleDetectRegionModalAccept}
                    onDecline={handleDetectRegionModalDecline}
                />
            )}
        </>
    );
};
