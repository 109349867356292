import { useMemo } from 'react';

import { Partners } from '@vtblife/layout-config/types';

import { useUserRegionContext } from '../shell';

const DEFAULT = 'default';

export function useActiveRegionPartner({ partners = {} }: { partners?: Partners }) {
    const { userRegionSelectedIdNarrow, userRegionSelectedId, userRegionDetectedId } = useUserRegionContext();
    const regionId = userRegionSelectedIdNarrow || userRegionSelectedId || userRegionDetectedId;

    const activePartner = useMemo(() => {
        if (!regionId) {
            return DEFAULT;
        }
        return Object.keys(partners).find((key) => {
            return partners[key].regionIds?.includes(regionId);
        });
    }, [regionId, partners]);

    return activePartner || DEFAULT;
}
