import React, { ReactElement } from 'react';
import { reportErrorToSentry } from '@vtblife/layout-components/shell/utils';

interface ErrorBoundaryProps {
    fallback?: ReactElement;
    extraInfo: any;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error): void {
        reportErrorToSentry({ error, extra: { extraInfo: this.props.extraInfo } });
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback || <h1>Произошла ошибка. Попробуйте обновить страницу.</h1>;
        }

        return this.props.children;
    }
}
