import { EventBus } from '@vtblife/event-bus';
import { ShowReportModal } from '@vtblife/event-bus-events';

import { ComprehensiveErrorData, extractQratorTransactionId } from '../../utils';

export const showQratorBlockReportModal = (
    extractedData: ComprehensiveErrorData,
    extra?: Record<string, any>,
    sentryEventId?: string,
    error?: any,
) => {
    const ip = extractedData.additionalData?.deviceIp;
    const traceId = extractedData.data?.traceId;
    const userId = extra?.userStore.user.userId;

    const emailBody =
        `ip: ${ip}\r\n` +
        `traceId: ${traceId}\r\n` +
        `sentryEventId: ${sentryEventId}\r\n` +
        `userId: ${userId}` +
        `qratorTransactionID: ${extractQratorTransactionId(error)}`;
    const event: ShowReportModal = {
        type: 'reportModal:show',
        category: 'simple',
        data: {
            email: {
                to: 'helpdesk@mail-m2.ru',
                subject: 'Блокировка Qrator',
                body: encodeURIComponent(emailBody),
            },
            message: `Что-то пошло не&nbsp;так. Запрос заблокирован.<br> IP адрес пользователя: ${ip} <br> Transaction ID: ${extractQratorTransactionId(
                error,
            )} `,
        },
    };
    EventBus.getInstance().publish(event);
};
