import { useState } from 'react';

export const useSupportSegment = () => {
    const [activeSupportSegment, setActiveSupportSegment] = useState({});

    return {
        activeSupportSegment,
        setActiveSupportSegment,
    };
};
