import { action, computed, observable } from 'mobx';
import { FooterConfig } from 'typings/configs';

import { RootStore } from './root';

export class FooterStore {
    rootStore: RootStore;

    @observable private footer?: FooterConfig;

    constructor(rootStore: RootStore, initialState?: InitialState['footerStore']) {
        this.rootStore = rootStore;
        if (initialState) {
            this.setInitialState(initialState);
        }
    }

    setInitialState(initialState: InitialState['footerStore']) {
        Object.assign(this, initialState);
    }

    @action
    setFooter(footer?: FooterConfig) {
        this.footer = footer;
    }

    @computed
    get top() {
        if (this.footer) {
            return this.footer.top;
        }
        return [];
    }

    @computed
    get middleLinks() {
        if (this.footer) {
            return this.footer.middleLinks;
        }
        return [];
    }

    @computed
    get socialLinks() {
        if (this.footer) {
            return this.footer.socialLinks;
        }
        return [];
    }

    @computed
    get downloadAppQrCode() {
        if (this.footer) {
            return this.footer.downloadAppQrCode;
        }
        return '';
    }

    @computed
    get logoHref() {
        return this.footer?.logoHref || '/';
    }
}
