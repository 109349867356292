import React from 'react';
import Icon from '@vtblife/uikit-icons';

import styles from './change-region-button.module.css';

interface ChangeRegionButtonProps {
    regionName: string;
    onClick: () => void;
}

export const ChangeRegionButton: React.FC<ChangeRegionButtonProps> = ({ regionName, onClick }) => {
    return (
        <div data-test="change-region-button">
            <button className={styles.button} onClick={onClick}>
                <span className={styles.buttonIcon}>
                    <Icon name="placemark" size="s" />
                </span>
                <span className={styles.buttonLink}>{regionName}</span>
            </button>
        </div>
    );
};
