export const parseRegionalPathname = ({
    pathname,
    regionalLandingRoutes,
    regionNames,
}: {
    pathname: string;
    regionalLandingRoutes: string[];
    regionNames: string[];
}) => {
    const regex = new RegExp(`^/?(${regionNames.join('|')})?(${regionalLandingRoutes.join('|')})$`);
    const result = withTrailingSlash(pathname).match(regex);

    if (!result) {
        return null;
    }
    const [, capital, route] = result;
    return { capital, route };
};

function withTrailingSlash(route: string): string {
    return route[route.length - 1] === '/' ? route : `${route}/`;
}
