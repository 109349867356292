import { HttpTransport, Transport } from './http-transport';
import { ClientConfig } from '../models/config';

export class ClientConfigApiService {
    transport: Transport;

    constructor(name = 'root', transport?: Transport) {
        this.transport =
            transport ||
            new HttpTransport({
                baseURL: `/api/${name}-bff/`,
            });
    }

    async loadClientConfig(): Promise<ClientConfig> {
        return this.transport.get('/api/get-regional-client-config');
    }
}

export const clientConfigApiService = new ClientConfigApiService();
