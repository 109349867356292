import { useState, useCallback, useMemo, useEffect } from 'react';
import Cookies from 'js-cookie';
import { EventBus } from '@vtblife/event-bus';
import {
    UserRegionChangeEvent,
    UserRegionChangeEventType,
    UserRegionInitEvent,
    UserRegionPayload,
    UserRegionUpdateEvent,
    UserRegionUpdateEventType,
} from '@vtblife/event-bus-events';

import {
    USER_REGION_ID_DETECTED_COOKIE,
    USER_REGION_ID_SELECTED_COOKIE,
    USER_REGION_ID_SELECTED_COOKIE_SEPARATOR,
} from '../../constants';
import { extractSecondLevelDomain } from '../../utils';
import { geoApiService } from '../../services/geo-api';
import { reportErrorToSentry } from '../utils';

const DEFAULT_REGION_ID = 3;

const SELECTED_COOKIE_MAX_AGE = 365;
const EMPTY_REGION_EXCEPTION_NAME = 'EMPTY_REGION_EXCEPTION';

class EmptyRegionException extends Error {
    constructor() {
        super();
        this.message = 'Could not find region with passed id';
        this.name = EMPTY_REGION_EXCEPTION_NAME;
    }
}

const userRegionInit = (userRegionId?: number, userRegionIdNarrow?: number) => {
    const userRegionInitEvent: UserRegionInitEvent = {
        type: 'userRegion:init',
        category: 'behavior',
        data: {
            userRegionId,
            userRegionIdNarrow,
        },
    };
    EventBus?.getInstance()?.publish(userRegionInitEvent);
};

const userRegionUpdate = ({ userRegionId, userRegionIdNarrow, userRegionName }: UserRegionPayload) => {
    const userRegionUpdateEvent: UserRegionUpdateEvent = {
        type: 'userRegion:update',
        category: 'behavior',
        data: {
            userRegionId,
            userRegionIdNarrow,
            userRegionName,
        },
    };
    EventBus?.getInstance()?.publish(userRegionUpdateEvent);
};

const userRegionChange = ({ userRegionId, userRegionIdNarrow, userRegionName }: UserRegionPayload) => {
    const userRegionChangeEvent: UserRegionChangeEvent = {
        type: 'userRegion:change',
        category: 'simple',
        data: {
            userRegionId,
            userRegionIdNarrow,
            userRegionName,
        },
    };
    EventBus?.getInstance()?.publish(userRegionChangeEvent);
};

const getCookieDomain = (hostname: string) => {
    if (hostname === 'localhost') return;
    if (hostname.includes('ingress')) return `.${hostname.substring(hostname.indexOf('ingress'))}`;
    return `.${extractSecondLevelDomain(hostname)}`;
};

const saveUserRegionSelectedCookie = (userRegionId?: number, userRegionIdNarrow?: number) => {
    if (!userRegionId) return;
    const userRegionIdSelected = `${userRegionId}${
        userRegionIdNarrow ? `${USER_REGION_ID_SELECTED_COOKIE_SEPARATOR}${userRegionIdNarrow}` : ''
    }`;
    Cookies.remove(USER_REGION_ID_SELECTED_COOKIE);
    Cookies.set(USER_REGION_ID_SELECTED_COOKIE, userRegionIdSelected.toString(), {
        expires: SELECTED_COOKIE_MAX_AGE,
        domain: getCookieDomain(location.hostname),
    });
};

export const useUserRegion = () => {
    const userRegionSelected = Cookies.get(USER_REGION_ID_SELECTED_COOKIE)?.split(
        USER_REGION_ID_SELECTED_COOKIE_SEPARATOR,
    );
    // Если USER_REGION_ID_DETECTED_COOKIE не обнаружена, то в детектед кидаем дефолтное значение
    const userRegionDetectedId = parseInt(Cookies.get(USER_REGION_ID_DETECTED_COOKIE) || '') || DEFAULT_REGION_ID;
    const [userRegionSelectedId, setUserRegionSelectedId] = useState<number | undefined>(
        parseInt(userRegionSelected?.[0] || ''),
    );
    const [userRegionSelectedIdNarrow, setUserRegionSelectedIdNarrow] = useState<number | undefined>(
        parseInt(userRegionSelected?.[1] || ''),
    );
    const [userRegionName, setUserRegionName] = useState<string>();

    const userRegionId = useMemo(
        () => userRegionSelectedId || userRegionDetectedId,
        [userRegionSelectedId, userRegionDetectedId],
    );

    const callInitEvent = useCallback(
        () => userRegionInit(userRegionSelectedId || userRegionDetectedId, userRegionSelectedIdNarrow),
        [userRegionSelectedId, userRegionDetectedId, userRegionSelectedIdNarrow],
    );

    const [isDetectRegionModalVisible, setIsDetectRegionModalVisible] = useState(false);
    const [isSelectRegionModalVisible, setIsSelectRegionModalVisible] = useState(false);

    const fetchUserRegion = useCallback(() => {
        geoApiService
            .getGeoObjectByIdV2(userRegionId!)
            .then(({ data }) => {
                if (!data) throw new EmptyRegionException();
                setUserRegionName(data.displayName);
            })
            .catch((error) => {
                if (!(error instanceof EmptyRegionException)) {
                    reportErrorToSentry({ error, extra: { userRegionId } });
                }
                geoApiService
                    .detectRegion()
                    .then(({ data }) => {
                        setUserRegionName(data.displayName);
                        setUserRegionSelectedId(undefined);
                        setUserRegionSelectedIdNarrow(undefined);
                    })
                    .catch((error) => {
                        reportErrorToSentry({ error });
                    });
            });
    }, [userRegionId]);

    const callSubscribe = useCallback(() => {
        const disposables = [
            EventBus.getInstance()?.subscribe<UserRegionUpdateEventType, UserRegionPayload>(
                'userRegion:update',
                ({ data }) => {
                    let hasChanged = false;

                    if (userRegionId !== data.userRegionId) {
                        setUserRegionSelectedId(data.userRegionId);
                        hasChanged = true;
                    }
                    if (userRegionSelectedIdNarrow !== data.userRegionIdNarrow) {
                        setUserRegionSelectedIdNarrow(data.userRegionIdNarrow);
                        hasChanged = true;
                    }
                    if (data.userRegionName && userRegionName !== data.userRegionName) {
                        setUserRegionName(data.userRegionName);
                    }

                    if (hasChanged) {
                        saveUserRegionSelectedCookie(data.userRegionId, data.userRegionIdNarrow);
                    }
                },
                {
                    eventCategory: 'behavior',
                },
            ),
        ];

        return () => {
            disposables.forEach((disposable) => disposable?.unsubscribe());
        };
    }, [
        userRegionId,
        setUserRegionSelectedId,
        userRegionSelectedIdNarrow,
        setUserRegionSelectedIdNarrow,
        userRegionName,
        setUserRegionName,
    ]);

    useEffect(() => {
        const disposables = [
            EventBus.getInstance()?.subscribe<UserRegionChangeEventType, UserRegionPayload>(
                'userRegion:change',
                ({ data: { userRegionId, userRegionIdNarrow, userRegionName } }) => {
                    setUserRegionSelectedId(userRegionId);
                    setUserRegionSelectedIdNarrow(userRegionIdNarrow);
                    if (userRegionName) setUserRegionName(userRegionName);
                    saveUserRegionSelectedCookie(userRegionId, userRegionIdNarrow);
                },
            ),
        ];

        return () => {
            disposables.forEach((disposable) => disposable?.unsubscribe());
        };
    }, [
        userRegionId,
        setUserRegionSelectedId,
        userRegionSelectedIdNarrow,
        setUserRegionSelectedIdNarrow,
        userRegionName,
        setUserRegionName,
    ]);

    useEffect(() => {
        if (userRegionId && userRegionName) {
            userRegionUpdate({
                userRegionId,
                userRegionName,
                userRegionIdNarrow: userRegionSelectedIdNarrow,
            });
        }
    }, [userRegionId, userRegionName, userRegionSelectedIdNarrow]);

    return {
        userRegionDetectedId,
        userRegionSelectedId,
        userRegionSelectedIdNarrow,
        userRegionName,
        userRegionId,
        isDetectRegionModalVisible,
        isSelectRegionModalVisible,
        callInitEvent,
        callSubscribe,
        fetchUserRegion,
        setUserRegion: userRegionChange,
        updateUserRegion: userRegionUpdate,
        showDetectRegionModal: useCallback(() => setIsDetectRegionModalVisible(true), []),
        hideDetectRegionModal: useCallback(() => setIsDetectRegionModalVisible(false), []),
        showSelectRegionModal: useCallback(() => setIsSelectRegionModalVisible(true), []),
        hideSelectRegionModal: useCallback(() => setIsSelectRegionModalVisible(false), []),
    };
};
