import { EventBus } from '@vtblife/event-bus';
import {
    CaptchaChallengePassedEventType,
    CaptchaChallengePassedPayload,
    CaptchaChallengeHiddenEventType,
    CaptchaChallengeHiddenPayload,
    CaptchaChallengeVisibleEventType,
    CaptchaChallengeVisiblePayload,
    CaptchaExecuteEventPayload,
    CaptchaExecuteResolvedEvent,
    ShowCaptcha,
} from '@vtblife/event-bus-events';

export function executeWithCaptcha({
    payload,
    retryCount = 0,
}: {
    payload: CaptchaExecuteEventPayload<any>;
    retryCount?: number;
}): Promise<void> {
    const eventBus = EventBus.getInstance();
    return new Promise((resolve) => {
        const subscriptions = [
            eventBus.subscribe<CaptchaChallengePassedEventType, CaptchaChallengePassedPayload>(
                'captcha:challenge-passed',
                async ({ data }) => {
                    payload.onCapthaPassed?.();
                    subscriptions.forEach((s) => s.unsubscribe());
                    try {
                        const response = await payload.callback(data.token);
                        const resolvedEvent: CaptchaExecuteResolvedEvent<any> = {
                            type: 'captcha:execute:resolved',
                            category: 'simple',
                            data: {
                                resolvedData: response,
                            },
                        };
                        eventBus.publish(resolvedEvent);
                    } catch (err) {
                        const status = err.response?.status;
                        if (status === 422 && retryCount < 2) {
                            executeWithCaptcha({ payload, retryCount: retryCount + 1 });
                        } else {
                            payload.onError?.(err);
                        }
                    } finally {
                        resolve();
                    }
                },
            ),
            eventBus.subscribe<CaptchaChallengeHiddenEventType, CaptchaChallengeHiddenPayload>(
                'captcha:challenge-hidden',
                () => {
                    payload.onCaptchaChallengeClosed?.();
                    subscriptions.forEach((s) => s.unsubscribe());
                    resolve();
                },
            ),
            eventBus.subscribe<CaptchaChallengeVisibleEventType, CaptchaChallengeVisiblePayload>(
                'captcha:challenge-visible',
                () => {
                    payload.onCaptchaChallengeVisible?.();
                },
            ),
        ];
        const showCaptchaEvent: ShowCaptcha = {
            type: 'captcha:show',
            category: 'simple',
            data: { useTrustToken: true },
        };
        eventBus.publish(showCaptchaEvent);
        payload.onCaptchaShow?.();
    });
}
