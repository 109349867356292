import { useEffect, useState } from 'react';
import type { AppMenuToggleEventType, AppMenuToggleEventData } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

const eventBus = EventBus.getInstance();

export const useMenuToggle = (initialState: boolean) => {
    const [isMenuOpened, setIsMenuOpened] = useState(initialState);
    useEffect(() => {
        const subscription = eventBus.subscribe<AppMenuToggleEventType, AppMenuToggleEventData>(
            'app:menu:toggle',
            (event) => setIsMenuOpened(event.data.isOpen),
        );

        return () => {
            subscription.unsubscribe();
        };
    }, []);
    return { isMenuOpened, setIsMenuOpened };
};
