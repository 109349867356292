import { action, computed, observable } from 'mobx';
import { EventBus } from '@vtblife/event-bus';

import { Sidebar, Logo } from '@vtblife/layout-config/types';

import { LogoVariant } from '@vtblife/layout-components/header/logo/images';

import { User } from '@vtblife/event-bus-events';

import { isBrowser } from '@vtblife/layout-components/utils';

import { RootStore } from './root';

export type CustomSidebarEvent = 'sidebar:config';
export type DefaultSidebarEvent = 'sidebar:config:default';

export class SidebarStore {
    rootStore: RootStore;
    private readonly eventBus = EventBus.getInstance();
    @observable private sidebar?: Sidebar;
    private defaultSidebar?: Sidebar;

    constructor(rootStore: RootStore, initialState?: InitialState['sidebarStore']) {
        this.rootStore = rootStore;
        if (initialState) {
            this.setInitialState(initialState);
            // при инициализации рута в неавторизованной зоне в качестве дефолтного ставился пустой конфиг
            if (this.rootStore.userStore.isAuthorized) {
                this.defaultSidebar = this.sidebar;
            }
        }

        if (isBrowser()) {
            this.eventBus.subscribe<CustomSidebarEvent, Partial<Sidebar> & { logo?: Logo; logoVariant?: LogoVariant }>(
                'sidebar:config',
                (event) => {
                    const { logo: eventLogo, logoVariant, items, footerItems } = event?.data || {};
                    const logo = eventLogo ? eventLogo : { variant: logoVariant || 'm2' };
                    this.setSidebar({
                        logos: [logo],
                        items: items || this.sidebar?.items || [],
                        footerItems: footerItems || this.sidebar?.footerItems || [],
                    });
                },
            );
            this.eventBus.subscribe<DefaultSidebarEvent, Partial<Sidebar>>('sidebar:config:default', () => {
                if (this.defaultSidebar) {
                    this.setSidebar(this.defaultSidebar);
                }
            });
        }
    }

    setInitialState(initialState: InitialState['sidebarStore']) {
        Object.assign(this, initialState);
    }

    @action
    setSidebar(sidebar: Sidebar) {
        this.sidebar = sidebar;
        if (!this.defaultSidebar && this.rootStore.userStore.isAuthorized) {
            this.defaultSidebar = this.sidebar;
        }
    }

    @computed get isInitialized() {
        return Boolean(this.sidebar);
    }

    sidebarConfig = (user: User | null): Omit<Sidebar, 'logos'> & { logo: Logo } => {
        const defaultLogo: Logo = { variant: 'm2' };
        if (this.sidebar) {
            let logo: Logo = (this.sidebar.logos || ([] as Array<Logo>)).filter((item: Logo) =>
                this.isSidebarLogoOptionAvailable(item, user),
            )[0];

            if (!logo) {
                logo = defaultLogo;
            }
            return {
                ...this.sidebar,
                logo,
            };
        }

        return { logo: defaultLogo, items: [], footerItems: [] };
    };

    private isSidebarLogoOptionAvailable = (logo: Logo, user: User | null): boolean => {
        const lockByUserRoleType = logo.userRoleTypes?.length && !logo.userRoleTypes.includes(user?.currentRoleType);
        return !lockByUserRoleType;
    };
}
