import React, { FC } from 'react';
import cn from 'classnames';

import { FooterLink } from '../footer-link';
import { MenuItemComponentProps } from '../../types/menu-item.interface';
import { FooterMiddleCol } from '../../types/footer.interface';
import styles from './middle.module.css';
import commonStyles from '../common.module.css';
import { useConfigContext } from '../../shell/contexts';

type MiddleProps = {
    middle: FooterMiddleCol[];
    menuItemComponent: React.FC<MenuItemComponentProps>;
};

export const Middle: FC<MiddleProps> = ({ menuItemComponent, middle }) => {
    const { cdnUrl } = useConfigContext();
    return (
        <div className={styles.middle}>
            {middle.map((col) => (
                <div key={col.title} className={styles.middleLine}>
                    <strong className={styles.middleLineTitle}>{col.title}</strong>
                    <ul className={cn(commonStyles.row, styles.middleRow)}>
                        {col.links.map((link) => (
                            <li key={link.href || link.to} className={cn(commonStyles.col, styles.middleCol)}>
                                <FooterLink component={menuItemComponent} cdnUrl={cdnUrl} {...link}>
                                    {link.name}
                                </FooterLink>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};
