import { action, computed, observable, toJS } from 'mobx';

import { BundleTree } from 'typings/configs';

import { RootStore } from './root';

export class BundleTreeStore {
    rootStore: RootStore;

    @observable bundleTree: BundleTree;

    constructor(rootStore: RootStore, initialState?: InitialState['bundleTreeStore']) {
        this.rootStore = rootStore;
        if (initialState) {
            this.setInitialState(initialState);
        }
    }

    setInitialState(initialState: InitialState['bundleTreeStore']) {
        Object.assign(this, initialState);
    }

    @computed get isInitialized() {
        return Boolean(this.bundleTree);
    }

    @computed
    get bundleTreeRoutes() {
        if (this.bundleTree) {
            return this.bundleTree.routes;
        }

        return [];
    }

    @computed
    get availableRoutes() {
        return this.bundleTreeRoutes.filter((route) => {
            if (this.rootStore.userStore.isAuthorized) {
                return (
                    route.availableForNotAuthorized ||
                    this.rootStore.userStore.isAllowed({
                        requiredPermissions: route.requiredPermissions,
                        requiredRoleTypes: route.requiredRoleTypes,
                        companyIds: route.companyIds,
                        blockCompanyIds: route.blockCompanyIds,
                    })
                );
            }
            return this.bundleTreeRoutes;
        });
    }

    @action
    setBundleTree(bundleTree: BundleTree) {
        this.bundleTree = bundleTree;
    }

    toJS(): BundleTree {
        return {
            routes: toJS(this.bundleTreeRoutes),
        };
    }
}
