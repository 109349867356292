import { EventBus } from '@vtblife/event-bus';
import { UserRegionPayload, UserRegionChangeEventType } from '@vtblife/event-bus-events';

import { isBrowser } from '@vtblife/layout-components/utils/is-browser';

import { RootStore } from './root';
import { isGatsbyRoute, scrollToTop } from '../utils';

export class UserRegionStore {
    rootStore: RootStore;
    gatsbyManifest: HtmlManifest;
    private readonly eventBus = EventBus.getInstance();

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        if (isBrowser()) {
            this.eventBus.subscribe<UserRegionChangeEventType, UserRegionPayload>(
                'userRegion:change',
                ({ data: { userRegionId, userRegionIdNarrow } }) => {
                    const { pathname } = this.rootStore.historyStore.location;
                    const routes = this.rootStore.configStore.gatsbyManifest?.map(({ route }) => route) || [];

                    if (isGatsbyRoute(routes, pathname)) {
                        const redirectPath = userRegionId
                            ? this.rootStore.configStore.getRegionalRedirect({ userRegionId, userRegionIdNarrow })
                            : null;

                        scrollToTop();
                        if (redirectPath) {
                            location.href = redirectPath;
                        } else {
                            location.reload();
                        }
                    }
                },
            );
        }
    }
}
