import { useState, useEffect, useCallback } from 'react';
import { ShowSurveyEventType, ShowSurveyPayload, SurveyType } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

import querystring from 'querystring';
import { useAuthorizeContext } from '../contexts';

export interface Survey {
    link: string;
    type: SurveyType;
}

export const useSurvey = () => {
    const { user } = useAuthorizeContext();
    const [surveys, setSurveys] = useState<Survey[]>([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const closeCurrentSurvey = useCallback(() => setSurveys((surveys) => surveys.slice(1)), [surveys]);
    const authIdQuery = user?.userId ? `ap_auth_id=${user.userId}&` : '';
    useEffect(() => {
        const sub = EventBus?.getInstance().subscribe<ShowSurveyEventType, ShowSurveyPayload>(
            'survey:show',
            (event) => {
                const survey: Survey = {
                    type: event.data.type,
                    // https://help.anketolog.ru/p/dopolnitelnye-parametry - как передавать пользовательские данные в опрос
                    link: `${event.data.link}?${authIdQuery}${querystring.stringify(event.data.query)}`,
                };
                setSurveys((surveys) => [...surveys, survey]);
            },
        );
        return () => {
            sub.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.userId]);

    return {
        currentSurvey: surveys[0],
        closeCurrentSurvey,
    };
};
