import React from 'react';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import { ComprehensiveErrorData } from '../../../../utils';

export const DetailedErrorContent: React.FC<ComprehensiveErrorData & { sentryEventId?: string }> = (props) => {
    const { data = {}, ui = {}, additionalData = {}, sentryEventId } = props;

    const { traceId, reasonMessage, date } = data;
    const { deviceIp } = additionalData;
    const { message, shouldRenderTechDetails, additionalMessage } = ui;

    return (
        <>
            {message && (
                <>
                    <div>{message}</div> <br />
                </>
            )}
            <div>{format(date || new Date(), 'dd.MM.yyyy, HH:mm:ss [zzzz]', { locale: ru })}</div>
            {traceId && <div>x-trace-id: {traceId}</div>}
            {!traceId && deviceIp && <div>device IP: {deviceIp}</div>}
            {!traceId && sentryEventId && <div>sentryEventId: {sentryEventId}</div>}
            {shouldRenderTechDetails && <>{reasonMessage && <div>{reasonMessage}</div>}</>}
            {additionalMessage}
        </>
    );
};
