import React, { Dispatch, SetStateAction } from 'react';
import { HeaderTab, Partners, Sidebar } from '@vtblife/layout-config/types';

import { MenuLinkInterface } from '../types/menu-link.interface';
import { HeaderPersonalButton } from '../types/header.interface';

export interface HeaderContext {
    items: HeaderTab[];
    authorizedItems?: Sidebar;
    isInfoMenuVisible?: boolean;
    isPersonalButtonsVisible?: boolean;
    personalButtons?: Record<string, HeaderPersonalButton>;
    partners?: Partners;
    activePartner?: string;
    activeRegionPartner?: string;
    onPartnerClick?: (name: string) => void;
    pathname?: string;
    roleType?: string;
    loginText: string;
    mobileLoginText: string;
    closeMenu: () => void;
    toggleMenu: () => void;
    setIsMenuOpened: Dispatch<SetStateAction<boolean>>;
    onMenuItemClick?: (e: React.MouseEvent, item: MenuLinkInterface) => void;
    isMenuOpened: boolean;
    logoutPath: string;
    loginPath: string;
    personalAreaPath: string;
    isPartnerLogoVariant: boolean;
    isUserInfoMenuOpened: boolean;
    isTransparent?: boolean;
    isTablet: boolean;
}

export const HeaderContextValue = React.createContext<HeaderContext>({
    items: [],
    loginText: 'Войти',
    mobileLoginText: 'Войти',
    closeMenu: () => null,
    toggleMenu: () => null,
    setIsMenuOpened: () => false,
    onMenuItemClick: () => null,
    isMenuOpened: false,
    logoutPath: '',
    isPartnerLogoVariant: false,
    loginPath: '/login',
    personalAreaPath: '/personal-area',
    isUserInfoMenuOpened: true,
    isTablet: true,
});
